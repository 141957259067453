import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ace-prequal-reports"
export default class extends Controller {
  connect() {
    $('#loading_data').hide()
    $('#from-filter, #to-filter').change(function(){
      if($(this).val() != ''){
        $(this).removeClass('error')
        $(this).next().remove()
      }
    });
    $('.search_field').click();

    $(".fromDate").datepicker({
         format: 'mm-dd-yyyy',
         autoclose: true,
     }).on('changeDate', function (selected) {
         var minDate = new Date(selected.date.valueOf());
         $('.toDate').datepicker('setStartDate', minDate);
     });
    $(".toDate").datepicker({
         format: 'mm-dd-yyyy',
         autoclose: true,
    }).on('changeDate', function (selected) {
      var minDate = new Date(selected.date.valueOf());
      $('.fromDate').datepicker('setEndDate', minDate);
    });
  }
  
  submitForm(){
    if($('#ace-prequal-reports').valid()){
      $('#loading_data').show()
    }
  }
}

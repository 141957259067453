import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sweetpay-user"
export default class extends Controller {
  connect() {
    $('.modal').css('cssText','z-index: 1051 !important')
    var roleVal = $('#roles').val()
    if(roleVal == "sweetpay_super_user"){
      $('.store').hide();
    }else{
      $('.store').show();
    }

    $('#roles').change(function(){
      var roleVal = $('#roles').val()
      if(roleVal == "sweetpay_super_user"){
        $('.store').hide();
      }else{
        $('.store').show();
      }
    });

    $('.right-arrow').click(function(){
      $('.sweetpay-user-stores-list').animate( { scrollLeft: '+=100' }, 100);
    })

    $('.left-arrow').click(function(){
      $('.sweetpay-user-stores-list').animate( { scrollLeft: '-=100' }, 100);
    })

    $('.select2').select2({
      multiple: true
    });

    $("#sweetpay_user").validate({
      rules: {
        "admin_user[first_name]": {
          required: true,
          nameValidation: true
        },
        "admin_user[last_name]": {
          required: true,
          nameValidation: true
        },
        "admin_user[email]": {
          emailregex: true,
          required: true
        },
         "admin_user[login_id]": {
          required: true,
          blankSpace: true
        },
        "admin_user[password]": {
          required: true,
          passwordregex: true
        },
        "admin_user[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "admin_user[first_name]": {
          required: "Please enter first name.",
          nameValidation: 'only alphabets allowed.'
        },
        "admin_user[last_name]": {
          required: "Please enter last name.",
          nameValidation: 'only alphabets allowed.'
        },
        "admin_user[email]": {
          required: "Please enter email address.",
          emailregex: 'Please enter valid email address.'
        },
        "admin_user[login_id]": {
          required: 'Please enter User ID.',
          blankSpace: 'Blank space not allowed.'
        },
        "admin_user[password]": {
          required: "Please enter password.",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "admin_user[password_confirmation]": {
          required: "Please enter confirm password.",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      },
    });

    $("#edit_sweetpay_user").validate({
      rules: {
        "admin_user[first_name]": {
          required: true,
          nameValidation: true
        },
        "admin_user[last_name]": {
          required: true,
          nameValidation: true
        },
        "admin_user[email]": {
          required: true,
          emailregex: true
        },
        "admin_user[login_id]": {
          required: true,
          blankSpace: true
        },
        "admin_user[password]": {
          required: false,
          passwordregex: true
        },
        "admin_user[password_confirmation]": {
          required: false,
          passwordregex: true
        }
      },
      messages: {
        "admin_user[first_name]": {
          required: "Please enter first name.",
          nameValidation: 'only alphabets allowed.'
        },
        "admin_user[last_name]": {
          required: "Please enter last name.",
          nameValidation: 'only alphabets allowed.'
        },
        "admin_user[email]": {
          required: "Please enter email address.",
          emailregex: 'Please enter valid email address.'
        },
        "admin_user[login_id]": {
          required: 'Please enter User ID.',
          blankSpace: 'Blank space not allowed.'
        },
        "admin_user[password]": {
          required: "Please enter password.",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "admin_user[password_confirmation]": {
          required: "Please enter confirm password",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      },
  });

    $.validator.addMethod("blankSpace", function(value, element) {
     if(value.replaceAll(' ', '').length == 0){
        return false
      }else{
        return this.optional(element) || true
      }
    });

    $.validator.addMethod("nameValidation", function(value, element) {
      return this.optional(element) || /^([a-zA-Z'-]+\s)*[a-zA-Z'-]+$/.test(value);
    });

    $.validator.addMethod("emailregex", function(value, element) {
      return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
    });

    $.validator.addMethod("passwordregex", function(value, element) {
      return this.optional(element) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/.test(value);
    });
  }

  selectAllStore(event){
    if($('#member_all:checked').length == 1){
      $('#store_ids').select2('destroy').find('option').prop('selected', 'selected').end().select2();
    }
    else{
      $('#store_ids').select2('destroy').find('option').prop('selected', false).end().select2();
    }
  }
}

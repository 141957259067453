import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--igw-ssn-details"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    igw_ssn_validator()
    applyDatePicker()
    $('#igw_ssn_detail_v3').validate({
      rules: {
        'applicant[birth_date]': {
          required: true,
          dateregex: true
        },
        'applicant[ssn]': {
          required: true,
          ssnregex: true,
          minlength: 11,
          maxlength: 11
        },
        'applicant[gross_annual_income]': {
          required: true,
          amountregx: true,
          maxlength: 9
        }
      },
      messages: {
        'applicant[birth_date]': {
          required: 'Please select date of birth.',
          dateregex: 'Date of birth must be in mm/dd/yyyy format.'
        },
        'applicant[ssn]': {
          required: 'Please enter your social security number.',
          ssnregex: 'Sorry, SSN must be 9 numeric digits only.',
          maxlength: 'Sorry, SSN must be 9 numeric digits only.',
          minlength: 'Sorry, SSN must be 9 numeric digits only.'
        },
        'applicant[gross_annual_income]': {
          required: 'Please enter gross income amount.',
          amountregx: 'Please enter gross income amount.',
          maxlength: 'Sorry, annual gross income must be less than 1,000,000.'
        }
      }
    });

    submitBtnEnableDisable();

    $('input').keyup(function(){
      submitBtnEnableDisable();
    });

    $('select').change(function(){
      submitBtnEnableDisable();
    })

    $('input[name="applicant[ssn]"]').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_birth_date').keyup(function(){
      addSlash($(this))
    })

    $('#igw_ssn_detail_v3').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });
  }
}

window.igw_ssn_validator = function(){
  $.validator.addMethod("ssnregex", function(value, element) {
    return this.optional(element) || /^(\+\d{1,1}\s)?\(?\d{3}\)?[\s.-]\d{2}[\s.-]\d{4}$/.test(value);
  });
  $.validator.addMethod("amountregx", function(value, element) {
    return this.optional(element) || /^[0-9,]+$/.test(value);
  });
  $.validator.addMethod("dateregex", function(value, element) {
    return this.optional(element) || /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  });
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--spi-admin-report"
export default class extends Controller {
  connect() {
    $('.spi_report_admin_member_id').select2({
      minimumInputLength: 3,
      placeholder: "All",
      allowClear: true
    });

    $('#loading_data').hide()
    $('#overall_data').parent().parent().show();
    if($('#report_view').val() == 'month_wise'){
      $('#overall_data').parent().parent().hide();
      if($('.month_date').val() != 'All'){

      }else{
        $('.month_date').val('All')
      }
    }else{
      $(".month_date").datepicker( {
        placeholder: 'All',
        format: "MM yyyy",
        endDate: '+1M',
        minViewMode: "months",
        maxViewMode: 'years',
        setDate:  new Date(),
        autoclose: true
      });
      var currentDate = new Date();
      var currentDay = currentDate.getDate();
      currentDate.setMonth(currentDate.getMonth() + 1, 1);
      
      if($('.month_date').val() != ''){

      }else{
        if (currentDay < 16) {
          $(".month_date").datepicker("setDate", new Date());
          currentDate.setDate(1);
        }else{
          $(".month_date").datepicker('setDate', currentDate);
        }
      }
    }

    $('#report_view').change(function(){
      if($('#report_view').val() == 'month_wise'){
        $('#overall_data').parent().parent().hide();
        $('#overall_data').prop('checked', false)
      }else{
        $('#overall_data').parent().parent().show();
      }
    })

    $('.month_date').change(function(){
      $(this).datepicker("hide");
      var month_name = $('.month_date').val()
      $('.month_date').val(month_name)
    })

    $('#report_view').change(function(){
      if(this.value == 'month_wise'){
        $('.month_date').val('All')
        $('.month_date').attr('disabled', true)
      }else{
        $('.month_date').attr('disabled', false)
        $(".month_date").datepicker( {
          format: "MM yyyy",
          endDate: '+1M',
          minViewMode: "months",
          maxViewMode: 'years',
          setDate:  new Date(),
          autoclose: true,
          clearBtn: true,
        });
        if (currentDay < 16) {
          $(".month_date").datepicker("setDate", new Date());
          currentDate.setDate(1);
        }else{
          $(".month_date").datepicker('setDate', currentDate);
        }
      }
    })

    $('.right-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '+=100' }, 100);
    })
    $('.left-arrow').click(function(){
      $('.spi_report_table').animate( { scrollLeft: '-=100' }, 100);
    })
  }

  submitForm(){
    if($('#spi_admin_report_form').valid())
      $('#loading_data').show()
    }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--ucfs-details"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    ucfsDetailValidator()
    $("#ucfs_form_v3").validate({
      rules: {
        'applicant[form_of_house]': {
          required: true
        },
        'applicant[employer_phone]':{
          required: true,
          maxlength: 12,
          minlength:12,
          phoneregex: true,
        },
        'applicant[previous_year_at_job]': {
          required: true,
          past_year: true,
        },
         'applicant[previous_address]': {
          required: true,
          addressregx: true,
          poboxregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[previous_city]': {
          required: true,
          minlength: 2,
          cityregex: true,
          maxlength: 40
        },
        'applicant[previous_apt_unit]': {
          maxlength: 20,
          aptunit: true
        },
        'applicant[previous_state_id]': {
          required: true
        },
        'applicant[previous_zip]': {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        },
        'applicant[auto_payment_method]':{
          required: true
        }
      },
      messages: {
        'applicant[form_of_house]': {
          required: 'Please select Housing Type.'
        },
        'applicant[employer_phone]': {
          required: 'Please enter Employer Phone.',
          minlength: 'Your employer phone number must be 10 numeric digits only.',
          maxlength: 'Your employer phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid employer phone.',
        },
        'applicant[previous_year_at_job]': {
          required: 'Please enter Previous years at job.',
          past_year: 'Please select at least 1 year.',
        },
        'applicant[previous_address]': {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
        },
        'applicant[previous_city]': {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        'applicant[previous_apt_unit]': {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
        },
        'applicant[previous_state_id]': {
          required: 'Please select State.'
        },
        'applicant[previous_zip]': {
          required: 'Please enter zip code.',
          zipregex: 'Please enter valid zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.'
        },
        'applicant[auto_payment_method]': {
          required: 'Please select payment account type.'
        }
      }
    });

     $('#applicant_savings, #applicant_checking').change(function() {
      var listDisplay = { 'debit_card': 'Debit Card', 'credit_card': 'Credit Card', 'savings': 'Savings', 'checking': 'Checking' }
      var list = ['debit_card', 'credit_card']

      if($('#applicant_checking').is(":checked")){
        list.push('checking')
      }

      if($('#applicant_savings').is(":checked")){
        list.push('savings')
      }

      $("#applicant_auto_payment_method").html('');
      $("#applicant_auto_payment_method").append('<option value="">Select</option>');
      $.each(list, function(index, item){
        $("#applicant_auto_payment_method").append( $('<option>', { value: item, text: listDisplay[item] }))
      });
    });

    $("#co_ucfs_form_v3").validate({
      rules: {
        'applicant[co_form_of_house]': {
          required: true
        },
         'applicant[co_employer_phone]':{
          required: true,
          maxlength: 12,
          minlength:12,
          phoneregex: true,
        },
         'applicant[co_previous_address]': {
          required: true,
          addressregx: true,
          poboxregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[co_previous_city]': {
          required: true,
          minlength: 2,
          cityregex: true,
          maxlength: 40
        },
        'applicant[co_previous_apt_unit]': {
          maxlength: 20,
          aptunit: true
        },
        'applicant[co_previous_state_id]': {
          required: true
        },
        'applicant[co_previous_zip]': {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        },
        'applicant[co_previous_year_at_job]': {
          required: true,
          co_past_year: true,
        },
        'applicant[co_auto_payment_method]':{
          required: true
        }
      },
      messages: {
        'applicant[co_form_of_house]': {
          required: 'Please select Housing Type.'
        },
        'applicant[co_employer_phone]': {
          required: 'Please enter Employer Phone.',
          minlength: 'Your employer phone number must be 10 numeric digits only.',
          maxlength: 'Your employer phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid employer phone.',
        },
        'applicant[co_previous_address]': {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
        },
        'applicant[co_previous_city]': {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        'applicant[co_previous_apt_unit]': {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
        },
        'applicant[co_previous_state_id]': {
          required: 'Please select State.'
        },
        'applicant[co_previous_zip]': {
          required: 'Please enter zip code.',
          zipregex: 'Please enter valid zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.'
        },
        'applicant[co_previous_year_at_job]': {
          required: 'Please enter Previous years at job.',
          co_past_year: 'Please select at least 1 year.',
        },
        'applicant[co_auto_payment_method]': {
          required: 'Please select auto payment method.'
        }
      }
    });
    submitBtnEnableDisable();

    $('input').keyup(function(){
      submitBtnEnableDisable();
    })

    $('select').change(function(){
      submitBtnEnableDisable();
    });

    $('#applicant_employer_phone').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_co_employer_phone').keyup(function(){
      addDashes($(this))
    })

    $('#co_ucfs_form_v3, #ucfs_form_v3').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });
  }
}

window.ucfsDetailValidator = function(){
  $.validator.addMethod("past_year", function(value, element) {
    var current_year = $('#applicant_previous_year_at_job').val();
    return this.optional(element) || current_year > 0;
  });

  $.validator.addMethod("co_past_year", function(value, element) {
    var current_year = $('#applicant_co_previous_year_at_job').val();
    return this.optional(element) || current_year > 0;
  });

  $.validator.addMethod("addressregx", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 .,-]+$/.test(value);
  });

  $.validator.addMethod("cityregex", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+(?:[- ][a-zA-Z ]+)*$/.test(value);
  });

  $.validator.addMethod("zipregex", function(value, element) {
    return this.optional(element) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  });

  $.validator.addMethod("poboxregex", function(value, element) {
    return this.optional(element) || !/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box[0-9]{0,10})\b/i.test(value);
  });
}

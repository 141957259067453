import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="payment-calculator"
export default class extends Controller {
  connect() {
  }

  saveLookup(event){
    let element = event.currentTarget
    let loanPurpose  = $(element).data('loan-purpose');
    let creditRating = $(element).data('credit-rating');
    let rangeKey     = $(element).data('range-key');
    let lenderId     = $(element).data('lender-id');
    let name         = $(element).data('name')
    let idString     = 'estimator_' + lenderId + '_' + loanPurpose + '_' + creditRating + '_' + rangeKey + '_'
    setMinMaxForEstimatorForCalculation(idString);

    $(`#${idString}${name}_value`).parent('form').validate();
    $('#' + $(element)[0].id + '_btn').click();
  }
}

window.setMinMaxForEstimatorForCalculation = function(idString){
  // 24 Term
  let low_apr_24                    = $('#' + idString + 'low_apr_24_value')
  let high_apr_24                   = $('#' + idString + 'high_apr_24_value')
  let low_monthly_payment_24_month  = $('#' + idString + 'low_monthly_payment_24_month_value')
  let high_monthly_payment_24_month = $('#' + idString + 'high_monthly_payment_24_month_value')

  // 36 Term
  let low_apr                       = $('#' + idString + 'low_apr_value')
  let high_apr                      = $('#' + idString + 'high_apr_value')
  let low_monthly_payment_36_month  = $('#' + idString + 'low_monthly_payment_36_month_value')
  let high_monthly_payment_36_month = $('#' + idString + 'high_monthly_payment_36_month_value')

  // 48 Term
  let low_apr_48                    = $('#' + idString + 'low_apr_48_value')
  let high_apr_48                   = $('#' + idString + 'high_apr_48_value')
  let low_monthly_payment_48_month  = $('#' + idString + 'low_monthly_payment_48_month_value')
  let high_monthly_payment_48_month = $('#' + idString + 'high_monthly_payment_48_month_value')

  // 60 Term
  let low_apr_60                    = $('#' + idString + 'low_apr_60_value')
  let high_apr_60                   = $('#' + idString + 'high_apr_60_value')
  let low_monthly_payment_60_month  = $('#' + idString + 'low_monthly_payment_60_month_value')
  let high_monthly_payment_60_month = $('#' + idString + 'high_monthly_payment_60_month_value')

  // 72 Term
  let low_apr_72                    = $('#' + idString + 'low_apr_72_value')
  let high_apr_72                   = $('#' + idString + 'high_apr_72_value')
  let low_monthly_payment_72_month  = $('#' + idString + 'low_monthly_payment_72_month_value')
  let high_monthly_payment_72_month = $('#' + idString + 'high_monthly_payment_72_month_value')

  // 120 Term
  let low_apr_120                    = $('#' + idString + 'low_apr_120_value')
  let high_apr_120                   = $('#' + idString + 'high_apr_120_value')
  let low_monthly_payment_120_month  = $('#' + idString + 'low_monthly_payment_120_month_value')
  let high_monthly_payment_120_month = $('#' + idString + 'high_monthly_payment_120_month_value')

  if(low_apr.length > 0){
    if( high_apr.val().length > 0 )
    low_apr.attr('max', high_apr.val())
    if( low_apr.val().length > 0 )
    high_apr.attr('min', low_apr.val())
    if( high_monthly_payment_36_month.val().length > 0 )
    low_monthly_payment_36_month.attr('max', high_monthly_payment_36_month.val())
    if( low_monthly_payment_36_month.val().length > 0 )
    high_monthly_payment_36_month.attr('min', low_monthly_payment_36_month.val())
  }

  if(low_apr_24.length > 0){
    if( high_apr_24.val().length > 0 )
    low_apr_24.attr('max', high_apr_24.val())
    if( low_apr_24.val().length > 0 )
    high_apr_24.attr('min', low_apr_24.val())
    if( high_monthly_payment_24_month.val().length > 0 )
    low_monthly_payment_24_month.attr('max', high_monthly_payment_24_month.val())
    if( low_monthly_payment_24_month.val().length > 0 )
    high_monthly_payment_24_month.attr('min', low_monthly_payment_24_month.val())
  }

  if(high_apr_48.length > 0){
    if( high_apr_48.val().length > 0 )
    low_apr_48.attr('max', high_apr_48.val())
    if( low_apr_48.val().length > 0 )
    high_apr_48.attr('min', low_apr_48.val())
    if( high_monthly_payment_48_month.val().length > 0 )
    low_monthly_payment_48_month.attr('max', high_monthly_payment_48_month.val())
    if( low_monthly_payment_48_month.val().length > 0 )
    high_monthly_payment_48_month.attr('min', low_monthly_payment_48_month.val())
  }

  if(low_apr_60.length > 0){
    if( high_apr_60.val().length > 0 )
    low_apr_60.attr('max', high_apr_60.val())
    if( low_apr_60.val().length > 0 )
    high_apr_60.attr('min', low_apr_60.val())
    if( high_monthly_payment_60_month.val().length > 0 )
    low_monthly_payment_60_month.attr('max', high_monthly_payment_60_month.val())
    if( low_monthly_payment_60_month.val().length > 0 )
    high_monthly_payment_60_month.attr('min', low_monthly_payment_60_month.val())
  }

  if(low_apr_72.length > 0){
    if( high_apr_72.val().length > 0 )
    low_apr_72.attr('max', high_apr_72.val())
    if( low_apr_72.val().length > 0 )
    high_apr_72.attr('min', low_apr_72.val())
    if( high_monthly_payment_72_month.val().length > 0 )
    low_monthly_payment_72_month.attr('max', high_monthly_payment_72_month.val())
    if( low_monthly_payment_72_month.val().length > 0 )
    high_monthly_payment_72_month.attr('min', low_monthly_payment_72_month.val())
  }

  if(low_apr_120.length > 0){
    if( high_apr_120.val().length > 0 )
    low_apr_120.attr('max', high_apr_120.val())
    if( low_apr_120.val().length > 0 )
    high_apr_120.attr('min', low_apr_120.val())
    if( high_monthly_payment_120_month.val().length > 0 )
    low_monthly_payment_120_month.attr('max', high_monthly_payment_120_month.val())
    if( low_monthly_payment_120_month.val().length > 0 )
    high_monthly_payment_120_month.attr('min', low_monthly_payment_120_month.val())
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="personal-prequal"
export default class extends Controller {
  static values = {
    id: Number,
    count: Number
  }
  connect() {
    $(".offer_loading").css("display", 'table');
    ficoScoreCheckPrequel(this.idValue, this.countValue);
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--product-return"
export default class extends Controller {
  connect() {
    $('.add-return').hide()
    $('.lender_name_btns').hide()

    $('.return_check_box-ip').each(function(_index, item){
      var id = $(item).data('id')
      if($(item).is(':checked')){
         $('#prouct_row_'+ id ).show()
      }else{
         $('#prouct_row_'+ id ).hide()
      }
    });
  }

  setReturnAll(event){
   var element = event.currentTarget
   if (element.checked){
     $('.return-product-row .return_check_box-ip:not(:disabled)').prop("checked", true)
     // $('.product_row_dynamic').show()
     $('.return_sale_tax').prop({disabled:true, readonly:true})
     $('.ready_for_return-sec').addClass('ready_for_return')
    }
    else{
     $('.return-product-row .return_check_box-ip:not(:disabled)').prop("checked", false)
     $('.return_sale_tax').prop({disabled:false, readonly:false})
     $('.ready_for_return-sec').removeClass('ready_for_return')
     // $('.product_row_dynamic').hide()
    }
    $('.return_check_box-ip').each(function(_index, item){
      var id = $(item).data('id')
      if($(item).is(':checked')){
         $('#prouct_row_'+ id ).show()
      }else{
         $('#prouct_row_'+ id ).hide()
      }
    })
   totalReturnOrder()
  }

  totalReturnOrder(){
    var total = 0
    $('.ready_for_return .return_amount_input').each(function(_index, item) {
      total += parseFloat($(item).val())
    });
    $('.total-return-order').text(total)
  }

  returnChecked(event){
    var element = event.currentTarget
    if(element.checked){
      $(element).parent().parent().parent().parent().addClass('ready_for_return');
      calculatorReturnTotal(element)
    }else{
      $(element).parent().parent().parent().parent().removeClass('ready_for_return');
    }
  }

  returnCheckedAndHideShow(event){
     var element = event.currentTarget
     var id = $(element).data('id')
      if(element.checked){
         $('#prouct_row_'+ id ).show()
      }else{
         $('#prouct_row_'+ id ).hide()
      }
  }

  calculatorReturnTotal(event){
    var element = event.currentTarget
    calculatorReturnTotal(element)
  }

  saleTaxValidReturn(event){
    let element = event.currentTarget
    var saleTax = 0
    $('.return_sale_tax').each(function(_index, item) {
      saleTax += parseFloat(item.value)
      // $(item).val(item.value);
    });
    var id = element.dataset.id
    var totalSaleTax = $(`.return-section-${id}`).data('sale-tax');

    if(saleTax > totalSaleTax){
      $('.return_sale_tax').css('border-color', 'red')
      $('.return_aff').prop('disabled', true)
    }else{
      $('.return_sale_tax').prop('style', '')
      $('.return_aff').prop('disabled', false)
      calculatorReturnTotal(element)
    }
  }

  settwoDecimalPoint(event){
    var element = event.currentTarget
    var value = element.value

    var num = parseFloat(value).toFixed(2)

    if (String(num) == 'NaN'){
      num = 0.00
    }
    $(element).val(num);
  }
  setTotalAmountAndSaleTax(){
    setTotalAmountAndSaleTax()
  }
}

window.totalReturnOrder = function(){
  var total = 0
  $('.ready_for_return .return_amount_input').each(function(_index, item) {
    total += parseFloat($(item).val())
  });
  $('.total-return-order').text(total)
}

window.calculatorReturnTotal = function(element){
  var id = element.dataset.id
  var qty = $(`.return-section-${id}`).data('quantity')
  // var p_unit = $(`.return-section-${id}`).data('unit')
  var p_unit = $(`.return-section-${id}`).data('unit')
  var saleTax = $(`.return-sale-tax-${id}`).val()
  var total = (parseFloat(qty) * parseFloat(p_unit)) + parseFloat(saleTax)
  $(`.return-total-${id}`).val(total)
}

window.setTotalAmountAndSaleTax = function(){
  var totalSaleTax = 0
  $('.ready_for_return .return_sale_tax').each(function(_index, item) {
    totalSaleTax += parseFloat(item.value)
  });

  var saleTax = $('.applicant-prod').data('sale-tax')
  saleTax = parseFloat(saleTax)
  if(saleTax < totalSaleTax){
    $('.return_sale_tax').css('border-color', 'red')
    $('.return_aff').prop('disabled', true)
  }else if (totalSaleTax == 0 && $('.ready_for_return').length == 0){
    $('.return_aff').prop('disabled', true)
  }
  else{
    $('.return_sale_tax').prop('style', '')
    $('.return_aff').prop('disabled', false)
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="projected-report"
export default class extends Controller {
  connect() {

    var currentDate = new Date();

    // Calculate the end date as two months from the current date

    // Calculate the start date based on the current date
    var startDate;
    if (currentDate.getDate() >= 16) {
        // If current date is 16th or later, start from next month
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        var endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0); // Last day of the month after two months
    } else {
        // Otherwise, start from the current month
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        var endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0); // Last day of the month after two months
    }

    $('.pba_eligibility_month').datepicker({
        format: "MM yyyy",
        startDate: startDate,
        endDate: endDate,
        minViewMode: "months",
        maxViewMode: 'years',
        autoclose: true
    });

    $('#loading_data').hide()

    if ($('.report_for_type').val() == 'first_payment_date_wise'){
      $('#date_period').show()
      $('.pba_eligibility_month').hide()
      $('#report_for_period_type').val('payment_date')
      $('#report_for_period_type').prop('disabled', true);
      $('#report_for_period_type').prop('disabled', false);
      $('.report_for_period_type').val('Payment Date')
    }
    else{
      $('.report_for_period_type').val('Servicing Period')
    }

    $('.report_for_type').change(function(e){
      if ($(this).val() == 'pba_eligible_date_wise'){
        $('#date_period').hide()
        $('.pba_eligibility_month').show()
        $('#report_for_period_type').val('servicing_period')
        $('.report_for_period_type').val('Servicing Period')
      }
      else{
        $('#date_period').show()
        $('.pba_eligibility_month').hide()
        $('#report_for_period_type').val('payment_date')
        $('.report_for_period_type').val('Payment Date')
      }
    })

    var filters = $('#projected_filter_values').data('filter')
    if(filters != undefined){
      var multipleSelection = [
                                'member_ids[]', 'spi_tier[]', 'account_status[]', 'payment_type[]',
                                'payment_status[]', 'pba_eligibility', 'report_for', 'report_for_period_type', 'date_period', 'pba_date_period'
                              ]

      $.each(multipleSelection, function(index, item){
        var data = filters[item]
        if(data != ''){
          $('select[name="' + item + '"').val(filters[item])
          // $('select[name="' + item + '"').select2();
        }else{
          $('select[name="' + item + '"').val(null);
          $('select[name="' + item + '"').trigger('change');
        }
      })

      var inputTextValue = [ 'pba_date_period' ]

      $.each(inputTextValue, function(index, item){
        $('input[name="' + item + '"]').val(filters[item])
      })

      if ($('.report_for_type').val() == 'pba_eligible_date_wise'){
        $('#date_period').hide()
        $('.pba_eligibility_month').show()
        $('#report_for_period_type').val('servicing_period')
        $('.report_for_period_type').val('Servicing Period')
      }
      else{
        $('#date_period').show()
        $('.pba_eligibility_month').hide()
        $('#report_for_period_type').val('payment_date')
        $('.report_for_period_type').val('Payment Date')
      }

      $('input[value="' + filters['report_type'] + '"]').click()

      $('input[name="pba_eligibility"]').prop('checked', Boolean(filters['pba_eligibility']))

      $('input[name="funded_date"]').prop('checked', Boolean(filters['funded_date']))
      $('input[name="contract_date"]').prop('checked', Boolean(filters['contract_date']))
      $('input[name="purchase_price"]').prop('checked', Boolean(filters['purchase_price']))
      $('input[name="plan_amount"]').prop('checked', Boolean(filters['plan_amount']))
      $('input[name="tier"]').prop('checked', Boolean(filters['tier']))
      $('input[name="account_status"]').prop('checked', Boolean(filters['account_status']))
      $('input[name="term"]').prop('checked', Boolean(filters['term']))
      $('input[name="apr"]').prop('checked', Boolean(filters['apr']))
      $('input[name="payment_date"]').prop('checked', Boolean(filters['payment_date']))
      $('input[name="monthly_payment"]').prop('checked', Boolean(filters['monthly_payment']))
      $('input[name="ach_date"]').prop('checked', Boolean(filters['ach_date']))
      $('input[name="down_payment"]').prop('checked', Boolean(filters['down_payment']))
      $('input[name="payment_owed"]').prop('checked', Boolean(filters['payment_owed']))
      $('input[name="payment_paid"]').prop('checked', Boolean(filters['payment_paid']))
      $('input[name="interchange_fee"]').prop('checked', Boolean(filters['interchange_fee']))
      $('input[name="ending_principal"]').prop('checked', Boolean(filters['ending_principal']))
      $('input[name="date_paid"]').prop('checked', Boolean(filters['date_paid']))
      $('input[name="interest_paid"]').prop('checked', Boolean(filters['interest_paid']))

    }

    $('#member_ids, #account_status_id, #payment_status, #spi_tier').select2({
      placeholder: "All",
      allowClear: true
    })

    $('#save_projected_filter').click(function(){
      var form = $('#projected_reports_form')
      var data = JSON.stringify(form.serialize())
      $('#save_filter_filters').val(data)
    });
    $('.btn-close').click()
    $('.main-section').scrollTop(0);
    $("#save_projected_filter").click(function(){
      $("#myModal").modal('show');
    });

    $(".month_date").datepicker( {
      format: "MM yyyy",
      endDate: '+1M',
      minViewMode: "months",
      maxViewMode: 'years',
      autoclose: true
    });
  }

  setProjectedSavedFilter(event){
  console.log("setSavedFilter_______________-------")
  let element = event.currentTarget
   $('#saved_projected_filter_input').val(element.value)

    if(element.value == ''){
      $('#filter_id').val('')
      $('#save_filter_name').val('')
    }else{
      $('#filter_id').val(element.value)
      var filter_name = $(element).find('option[value="' + element.value + '"]').text()
      $('#save_filter_name').val(filter_name)
    }
    $("#saved_projected_filter_btn").click();
  }

  clickSaveMapping(){
    $("#save_projected_filter").click()
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--llp-dropdown"
export default class extends Controller {
  connect() {
    var id = this.context.scope.element.dataset.id
    var dataList = $(`#lender_loan_purpose_dropdown-${id} input`).val()
    var data = JSON.parse(dataList)
    var selectedValue = $(`#lender_loan_purpose_forms-${id} select`).val()
    var select = $(`#lender_loan_purpose_forms-${id} select`)
    select.last().html('')
    $.each(data, function(index, item){
      select.append('<option value="' + item[1] + '">' + item[0] + '</option>')
    });
    select.val(selectedValue)
  }
}

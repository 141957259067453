import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ucfs-bank-info"
export default class extends Controller {
  connect() {
      validation_checker()
      allZerovalidator()
      $('#bank_info_form').validate({
        rules: {
          'routing_number': {
            minlength: 9,
            maxlength: 9,
            required: true,
            digits: true,
            allZero: true
          },
          'account_number': {
            required: true,
            digits: true,
            minlength: 6,
            maxlength: 25,
            allZero: true
          },
          'bank_name':{
            maxlength: 256,
            required: true
          }
        },
        messages: {
          'routing_number': {
            minlength: 'Sorry, routing number must be 9 digits',
            maxlength: 'Sorry, routing number must be 9 digits',
            digits: 'Sorry, only numbers are allowed.',
            allZero: 'Sorry, number is not valid.'

          },
          'account_number': {
            maxlength: "Sorry, account number must be between 6 and 25 digits.",
            minlength: "Sorry, account number must be between 6 and 25 digits.",
            digits: 'Sorry, only numbers are allowed.',
            allZero: 'Sorry, number is not valid.'
          },
          'bank_name':{
            maxlength: "Sorry, bank name must be less then 256 words"
          }
        }
      });

      $('#routing_number, #account_number, #bank_name').keyup(function(){
        validation_checker();
      })
    }
}
window.allZerovalidator = function(){
  $.validator.addMethod("allZero", function(value, element) {
    return this.optional(element) || !(! + value);
  });
}

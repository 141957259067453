import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products--upgrade-product-section"
export default class extends Controller {
  connect() {
    console.log('upgarde_product_form')
    $('#upgrade_product_form input').keyup(function(){
       validation_checker()
    });
  }

  setMaxAmount(){
    console.log('call setMaxAmount')
    var final_max_amount, initial_max_amount
    let element = event.currentTarget
    final_max_amount = $('#product-info').data('maxAmountForFinal')
    initial_max_amount = $('#product-info').data('maxAmountForInitial')
    if(element.checked){
      $('#product_amount').prop('max', final_max_amount)
    }else{
      $('#product_amount').prop('max', initial_max_amount)
    }
  }

}

window.validation_checker = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs;
  if($('#applicant_info').length == 1){
    myInputs = $('#applicant_info input:required, #applicant_info select:required, #applicant_info textarea:required').filter( ":visible" );
  }else{
    myInputs = $('input:required, select:required, textarea:required');
  }

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  totalInputs = myInputs.length

  if ( $('#product-info').length == 1 && $('.nested-fields').length == 0) {
    totalInputs += 1
  }

  if (inputsWithValues == totalInputs) {
    $(".submission").prop("disabled", false);
  } else {
    $(".submission").prop("disabled", true);
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="estimatore"
export default class extends Controller {
  connect() {
    $('#estimators select').change(function(){
      lookupCalculate()
    });
    console.log('connect')

    $('.right-arrow').click(function(){
      console.log('click on right arrow')
      $('.payment_calculator_table').animate( { scrollLeft: '+=100' }, 100);
    })
    $('.left-arrow').click(function(){
      console.log('click on left arrow')

      $('.payment_calculator_table').animate( { scrollLeft: '-=100' }, 100);
    })
  }
  disableLoanAmountByCreditRating(event){
    var element = event.currentTarget
    var creditRating = element.value
    if(creditRating != 'fair'){
      $("#lookup-loan-amount  option[value='High']").prop('disabled', false);
    }
  }
  hideLoanPurpose(event){
    var element = event.currentTarget
    var lender_name = $(element).find('option[value="' + element.value + '"]').text()
    var names = ['Upgrade ACx', 'Upgrade']
    if(names.includes(lender_name)){
      $('#lookup-loan-purpose option[value="debt_consolidation"]').attr("selected", "selected");
      $('#llp-hide').hide()
    }else{
      $('#llp-hide').show()
    }
  }
}

window.lookupCalculate = function(){
  var lookupLenderId     = $('#lookup-lender-id').val();
  var lookupLoanPurpose  = $('#lookup-loan-purpose').val();
  var lookupCreditRating = $('#lookup-credit-rating').val();
  var lookupLoanAmount = $('#lookup-loan-amount').val();
  if(lookupLenderId.length > 0 && lookupLoanPurpose.length > 0 && lookupCreditRating.length > 0 && lookupLoanAmount.length > 0){
    if (lookupCreditRating == 'fair' && lookupLoanAmount == 'High'){
      $("#lookup-loan-amount").val('Low');
      $("#lookup-loan-amount  option[value='High']").prop('disabled', true);
      $('#estimators select').change()
    }else{
      if($('#estimators select').valid())
        $('#lookup-table-loading').show()
        $('#estimators-save').click();
    }
  }
}
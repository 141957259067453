import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products--product-form"
export default class extends Controller {
  connect() {
    if($('.nested-fields').length == 0) {
      clickAddFields()
    }
    $('#applicant_delivery_at').datepicker({
      showOn: "button",
      autoclose: true,
      format: 'mm-dd-yyyy',
      todayHighlight: true,
      startDate: '1D',
      endDate: '+1Y'
    });

    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
  }
}

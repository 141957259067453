import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--banking-information"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    $('#banking_information_v3').validate({
      rules: {
        'applicant[routing_number]': {
          minlength: 9,
          maxlength: 9,
          required: true,
          digits: true,
          allZero: true
        },
        'applicant[account_number]': {
          required: true,
          digits: true,
          minlength: 6,
          maxlength: 25,
          allZero: true
        },
        'applicant[bank_name]':{
          maxlength: 256,
          required: true,
          blankSpace: true,
          alphabetWithSplSym: true
        }
      },
      messages: {
        'applicant[routing_number]': {
          minlength: 'Sorry, routing number must be 9 digits.',
          maxlength: 'Sorry, routing number must be 9 digits.',
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.',
          required: 'enter routing number.'

        },
        'applicant[account_number]': {
          maxlength: "Sorry, account number must be between 6 and 25 digits.",
          minlength: "Sorry, account number must be between 6 and 25 digits.",
          digits: 'Sorry, only numbers are allowed.',
          allZero: 'Sorry, number is not valid.',
          required: 'enter account number.'

        },
        'applicant[bank_name]':{
          maxlength: "Sorry, bank name must be less then 256 words.",
          required: 'Please enter bank name.',
          blankSpace: 'Blank space not allowed.',
          alphabetWithSplSym: 'Please enter valid bank name.'
        }
      }
    });

    $('input').keyup(function(){
      submitBtnEnableDisable();
    })

    $('#banking_information_v3').on('mouseover', function function_name(argument) {
      submitBtnEnableDisable();
    });

    $.validator.addMethod("allZero", function(value, element) {
      return this.optional(element) || !(! + value);
    });

    $.validator.addMethod("alphabetWithSplSym", function(value, element) {
      return this.optional(element) || /^(?=.*[A-Za-z\d]{2,})[A-Za-z\d!@#$%^&*()_+~=`~-]{2,}/.test(value);
    });

    $.validator.addMethod("blankSpace", function(value, element) {
      if(value.replaceAll(' ', '').length == 0){
        return false
      }else{
        return this.optional(element) || true
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--identity-details"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    applyDatePicker()
    validatorIdentityDetail()
    $('#identity_detail_v3').validate({
      rules: {
        'applicant[birth_date]': {
          required: true,
          dateregex: true
        },
        'applicant[ssn]': {
          required: true,
          ssnregex: true,
          minlength: 11,
          maxlength: 11
        }
      },
      messages: {
        'applicant[birth_date]': {
          required: 'Please select date of birth.',
          dateregex: 'Date of birth must be in mm/dd/yyyy format.'
        },
        'applicant[ssn]': {
          required: 'Please enter your social security number.',
          ssnregex: 'Sorry, SSN must be 9 numeric digits only.',
          maxlength: 'Sorry, SSN must be 9 numeric digits only.',
          minlength: 'Sorry, SSN must be 9 numeric digits only.'
        }
      }
    });

    if($("#co_identity_detail_v3").length == 1){
      $("#co_identity_detail_v3").validate({
        rules: {
          "applicant[co_birth_date]": {
            required: true,
            dateregex: true
          },
         "applicant[co_ssn]" :{
            required: true,
            ssnregex: true
         },
         "applicant[co_gross_annual_income]": {
            required: true,
            amountregx: true,
            minIncomeZero: true,
            maxlength: 9,
            annual_gross_max: true
          }
       },
       messages: {
          "applicant[co_birth_date]": {
            required: 'Please select date of birth.',
            dateregex: 'Date of birth must be in mm/dd/yyyy format.'
          },
          "applicant[co_ssn]": {
            required: 'Please enter your social security number.',
            ssnregex: 'Sorry, SSN must be 9 numeric digits only.',
            maxlength: 'Sorry, SSN must be 9 numeric digits only.',
            minlength: 'Sorry, SSN must be 9 numeric digits only.'
          },
          "applicant[co_gross_annual_income]": {
            required: 'Please enter gross income amount.',
            amountregx: 'Please enter gross income amount.',
            minIncomeZero: 'Sorry, annual gross income must be greater than zero.',
            maxlength: 'Sorry, annual gross income must be less than 1000000.',
            annual_gross_max: 'Sorry, annual gross income must be less than 1000000.'
          },
       }
      });

       $('#applicant_co_ssn').rules('add', {
        primarynotallow: true,
        messages: {
          primarynotallow: "SSN needs to be different from primary applicant."
        }
      });

      $.validator.addMethod("primarynotallow", function(value, element) {
        var primary_ssn = $('#primary_ssn').val();
        return this.optional(element) || (primary_ssn != value);
      });
    }

    $('#applicant_birth_date, #applicant_co_birth_date').keyup(function(){
      addSlash($(this))
    })

    submitBtnEnableDisable();

    // $('input, select').on('keyup, change', function function_name(argument) {
    //   submitBtnEnableDisable();
    // });

    $('input').keyup(function(){
      submitBtnEnableDisable();
    });

    $('select').change(function(){
      submitBtnEnableDisable();
    })

    $('#identity_detail_v3, #co_identity_detail_v3').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });

    $('input[name="applicant[ssn]"]').keyup(function(){
      addDashes($(this))
    })
    $('input[name="applicant[co_ssn]"]').keyup(function(){
      addDashes($(this))
    })

  }
}

window.validatorIdentityDetail = function(){
  $.validator.addMethod("ssnregex", function(value, element) {
    return this.optional(element) || /^(\+\d{1,1}\s)?\(?\d{3}\)?[\s.-]\d{2}[\s.-]\d{4}$/.test(value);
  });
  $.validator.addMethod("annual_gross_max", function(value, element) {
    return this.optional(element) || Number(value.replace(/,/g, ''))<= 1000000;
  });
  $.validator.addMethod("amountregx", function(value, element) {
    return this.optional(element) || /^[0-9,]+$/.test(value);
  });

  $.validator.addMethod("minIncomeZero", function(value, element) {
    // var incomeAmount = 1 // $('#min_income').val()
    // if(incomeAmount > 0){
    //   incomeAmount = Number(incomeAmount)
    // }else{
    //   incomeAmount = 10000
    // }
    return this.optional(element) || Number(value.replace(/,/g, '')) >= 1;
  });
  $.validator.addMethod("dateregex", function(value, element) {
    return this.optional(element) || /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  });

}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="prequal-call"
export default class extends Controller {
  static values = {
    id: Number
  }
  connect() {
    $(".offer_loading").css("display", 'table');
    getPrequel(this.idValue, 1)
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--lender-loan-purpose"
export default class extends Controller {
  connect() {
    $('.llp-form-section').each(function(_index, item){
      $(item).validate()
    });

    setTimeout(function(){
      $('.lender_loan_purpose_forms_new').each(function(_index, item){
        if($(item).text() == ""){
          $(item).next().find('a').show();
          $(item).next().find('.dummy').hide();
        }
      });
    }, 500);
  }

  updateMaxMinAmountLlp(event){
    var element = event.currentTarget
    var type = element.dataset.type
    if(type == 'max'){
      var ip = $(element).parent().next().find('input')
      ip.prop('max', element.value)
      ip.prop('title', `Min loan amount should be less than ${element.value}.`)
    }else{
      var ip = $(element).parent().prev().find('input')
      ip.prop('min', element.value)
      ip.prop('title', `Max loan amount should be greater than ${element.value}.`)
    }
  }

  enableInput(event){
    var element = event.currentTarget
    var type = element.dataset.type
    $('.'+type).prop('disabled', false)
    $('.submit_'+type).removeClass('hide')
    $('.submit_'+type).addClass('show')
    $(element).addClass('hide')
  }

}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lender"
export default class extends Controller {
  connect() {
    stateCount()
    var statesIds = $('#lender_states').val()
    var states = $('#lender_states option:selected').map(function() {
        return $(this).text();
    }).get().join(', ');
    $('.state').text(states);

    console.log('asdasdasd')

    $('.right-arrow').click(function(){
      $('.lender_table').animate( { scrollLeft: '+=100' }, 100);
    })

    $('.left-arrow').click(function(){
      $('.lender_table').animate( { scrollLeft: '-=100' }, 100);
    })

  }
  toggleStateList() {
    $('.add_column_list').toggle();
  }
  selectStateByCheckbox(event) {
    var element = event.currentTarget
    var id = $(element).data('id');
    $('#lender_states option[value="'+ id + '"]').prop('selected', element.checked);
    addColumnItemInReport(element)
    stateCount()
  }

  llpAddButton(event){
    var element  = event.currentTarget
    $(element).find('a').hide()
    $(element).find('.dummy').show()
  }

  delete_loan_purpose(event){
    var element  = event.currentTarget
    var id =  $(element).data('id')
    var llp_id = $(element).data('llp-id')
    var r = confirm("Are you sure??");
    if(r == true){
      $.ajax({
        type: "delete",
        url: "/admin/lenders/"+id+"/delete_loan_purpose?llp_id="+llp_id,
        data: {},
        contentType: "application/json",
        success: function(res){
        }
      });
    }
  }
}
window.stateCount = function(){
  var counts = 0;
  var add_column = []
  var prefixs = ''
  var message = ''
  $('.add_state_item input:checkbox').each(function(index, item){
    if(item.checked){
      add_column.push(item.id)
      counts  = $('.add_state_item input:checked').length
      prefixs = counts < 2 ? '' : 's'
      message = counts < 1 ? 'Add State' : counts + " state" + prefixs + " added"
      $('#select_state_column').text(message)
    }else{
      prefixs = counts < 2 ? '' : 's'
      message = counts < 1 ? 'Add State' : counts + " state" + prefixs + " added"
      $('#select_state_column').text(message)
    }
  });
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="get-offer"
export default class extends Controller {
  static values = {
    id: Number
  }

  connect() {
    $(".offer_loading").css("display", 'table');
    get_offer(this.idValue, 1)
  }
}

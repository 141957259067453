let applicant_id, form_type, count;

function signUrl(id, repeat_count){
  applicant_id = id
  count        = repeat_count
  form_type 	 = 'form_4'
  call()
}

function recallSignUrl(id, repeat_count){
  setTimeout( function(){
   signUrl(id, repeat_count)
  }, 5000);
}

function call(){
  $.ajax({
    type: 'GET',
    url: '/products/' + applicant_id + '/sign_url?form_type='+form_type+'&count=' + count,
    success: function(data) {
    }
  });
}

export { signUrl, recallSignUrl };
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--product-details"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });

    if($('.nested-fields').length == 0) {
      clickAddFields()
    }

    $('#product-forms').mouseover(function(){
      submitBtnEnableDisable()
      productRulesSet()
    });

    $('#product_detail_v3').mouseover(function(){
      submitBtnEnableDisable()
      productRulesSet()
    });

    $('.product_unit_price').keyup(function(){
      maxLimit($(this))
    });

    $('.product_unit_price').change(function() {
      twoDecimalPoint($(this))
      calculate($(this))
    });

    $('.product_quantity').change(function() {
      calculate($(this))
    });

    $('#applicant_sale_tax').keyup(function(){
      maxLimit($(this))
    });

    $('#applicant_sale_tax').change(function() {
      twoDecimalPoint($(this))
      calculate($(this))
    });
  }
}

window.clickAddFields = function() {
  $('.add_fields').click()
}

window.productRulesSet = function(){
  $('#product_details').validate()
  if($('.nested-fields').length >= 10){
    $('.add-item').hide();
  }
  else{
    $('.add-item').show();
  }
}
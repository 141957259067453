import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--id-information-form"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    validator();
    $('#id_information').validate({
      rules: {
        'applicant[id_type]': {
          required: false
        },
        'applicant[id_number]': {
          required: true,
          maxlength: 65,
          alphanumeric: true
        },
        'applicant[expiration_date]': {
          required: true,
          dateregex: true
        }
      },
      messages: {
        'applicant[id_type]': {
          required: 'Please select id type'
        },
        'applicant[id_number]': {
          required: 'Please enter id number',
          maxlength: 'ID number must be 10 alphanumeric only.',
          alphanumeric: 'Please use alphanumeric value only.',
        },
        'applicant[expiration_date]': {
          required: 'Please enter expiration date.',
          dateregex: 'Expiration date must be in mm/dd/yyyy format.'
        }
      }
    });

    $('#co_id_information').validate({
      rules: {
        'applicant[co_id_type]': {
          required: false
        },
        'applicant[co_id_number]': {
          required: true,
          maxlength: 65,
          alphanumeric: true
        },
        'applicant[co_expiration_date]': {
          required: true,
          dateregex: true
        }
      },
      messages: {
        'applicant[co_id_type]': {
          required: 'Please select id type'
        },
        'applicant[co_id_number]': {
          required: 'Please enter id number',
          maxlength: 'ID number must be 10 alphanumeric only.',
          alphanumeric: 'Please use alphanumeric value only.',
        },
        'applicant[co_expiration_date]': {
          required: 'Please enter  expiration date.',
          dateregex: 'Expiration date must be in mm/dd/yyyy format.'
        }
      }
    });

    $('#applicant_expiration_date, #applicant_co_expiration_date').keyup(function(){
      addSlash($(this))
    })

    $("#applicant_expiration_date").datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date()
    });

    $("#applicant_co_expiration_date").datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date()
    });
    submitBtnEnableDisable();
    $('input, select').keyup(function(){
      submitBtnEnableDisable();
    })

    $('input, select').change(function(){
      submitBtnEnableDisable();
    })

    $('#id_information, #co_id_information').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });

  }
}

window.validator = function(){
  $.validator.addMethod("alphanumeric", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 ]+$/.test(value);
  });
  $.validator.addMethod("dateregex", function(value, element) {
    return this.optional(element) || /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  });

}

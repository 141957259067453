// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { short_form_offer, recall_for_short, long_form_offer, recall_for_long, ficoScoreCheck, ficoScoreCheckV3, get_offer, recall_for_get_offer, get_offer_for_form4, recall_for_form4, get_prequel, ficoScoreCheckPrequel } from "./offer.js";
import { signUrl, recallSignUrl } from "./finalize.js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbol:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('#payment_calculate select').change(function(){
    paymentCalculate()
  });
})

window.get_offer        = (applicant_id,count) =>  {get_offer(applicant_id,count)}
window.get_offer_for_form4  = (applicant_id,count) =>  {get_offer_for_form4(applicant_id,count)}
window.recall_for_get_offer = (applicant_id, count) => { recall_for_get_offer(applicant_id, count) }
window.recall_for_form4 = (applicant_id, count) => { recall_for_form4(applicant_id, count) }
window.short_form_offer = (applicant_id, count) => { short_form_offer(applicant_id, count) }
window.recall_for_short = (applicant_id, count) => { recall_for_short(applicant_id, count) }
window.long_form_offer  = (applicant_id, count) => { long_form_offer(applicant_id, count) }
window.recall_for_long  = (applicant_id, count) => { recall_for_long(applicant_id, count) }
window.signUrl          = (applicant_id, count) => { signUrl(applicant_id, count) }
window.recallSignUrl    = (applicant_id, count) => { recallSignUrl(applicant_id, count) }
window.ficoScoreCheck   = (applicant_id, count) => { ficoScoreCheck(applicant_id, count) }
window.ficoScoreCheckV3   = (applicant_id, count) => { ficoScoreCheckV3(applicant_id, count) }
window.getPrequel        = (applicant_id,count) =>  {get_prequel(applicant_id,count)}
window.ficoScoreCheckPrequel = (applicant_id, count) => { ficoScoreCheckPrequel(applicant_id,count) }
window.offerConfirmationBox = (element) => {
  let url_value = element.data('offer-url');
  $("#linkurl").prop("href", url_value);
}
window.intervalValue = '';
window.timeout_session  = (encoded_url = '') => {
  clearInterval(intervalValue);
  let session_time = 900; // 15 min
  let current_session_time = 0;
  if (encoded_url == ''){
    encoded_url = $('.encoded_url').data('encoded_url')
  }
  intervalValue = setInterval( function(){
  current_session_time > session_time ? window.location = '/' + encoded_url : current_session_time += 1
  // console.clear('')
  // console.log('==>' + current_session_time)
  }, 1000);
}

// window.addDashes = (element) => {
//   var key_code = event.keyCode
//   if(key_code == 8 || key_code == 37 || key_code == 39) //backspace, left, right key event reject
//     return false;
//   var r = /(\D+)/g,
//   npa = '',
//   nxx = '',
//   last4 = '';
//   element.val(element.val().replace(r, ''));
//   npa = element.val().substr(0, 3);
//   if(element[0]['id'] == "applicant_ssn" || element[0]['id'] == "applicant_co_ssn"){
//     nxx = element.val().substr(3, 2);
//     last4 = element.val().substr(5, 4);
//   }else{
//     nxx = element.val().substr(3, 3);
//     last4 = element.val().substr(6, 4);
//   }
//   var dash_number = npa + '-' + nxx + '-' + last4
//   if(dash_number != '--')
//     element.val(npa + '-' + nxx + '-' + last4);
// }

window.removeZeroFromStart = function(value){
  return String(parseInt(value.replace(/,/g , '')))
}

window.addSlash = (element) => {
  var key_code = event.keyCode
  if(key_code == 8 || key_code == 37 || key_code == 39) //backspace, left, right key event reject
    return false;
  var r = /(\D+)/g,
  npa = '',
  nxx = '',
  last4 = '';
  element.val(element.val().replace(r, ''));
  npa = element.val().substr(0, 2);
  nxx = element.val().substr(2, 2);
  last4 = element.val().substr(4, 4);
  var dash_number = npa + '/' + nxx + '/' + last4
  if(dash_number != '//')
    element.val(npa + '/' + nxx + '/' + last4);
}

// window.numberFormatter = (element) => {
//   var key_code = event.keyCode
//   if(key_code == 8 || (event.which >= 37 && event.which <= 40)) //backspace, left, right key event reject
//     return false;
//    $(element).val(function(index, value) {
//     return removeZeroFromStart(value)
//     .replace(/\D/g, "")
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//     ;
//   });
// }

// window.numberFormatterWithDecimal = (element) => {
//   var key_code = event.keyCode
//   if(key_code == 8 || (event.which >= 37 && event.which <= 40)) //backspace, left, right key event reject
//     return false;
//    $(element).val(function(index, value) {
//     var part_first  = value.split('.')
//     var part_second = ''

//     part_first = removeZeroFromStart(part_first[0])
//     .replace(/\D/g, "")
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//     ;

//     part_second = value.split('.')[1]

//     if(part_second == undefined){
//       return part_first
//     }else{
//       part_second = (part_second.length > 2 ? parseFloat(value.replace(/,/g , '')).toFixed(2).split('.')[1] : part_second)
//       return part_first + '.' + part_second
//     }

//   });
// }

// window.calculate = function(element){
//   var amount = 0
//   var qty = 0
//   var price = 0
//   qty   = element.parent().parent().children().children('.quantity').val()
//   price = element.parent().parent().children().children('.unit_price').val()

//   if( qty != ''){
//     qty = parseFloat(qty)
//   }
//   if( price != ''){
//     price = parseFloat(price)
//   }
//   element.parent().parent().children().children('.amount').text((price*qty).toFixed(2))
//   element.parent().parent().children().children('.amount').next().val((price*qty).toFixed(2))
//   total_calculate()
// }

// window.total_calculate = function (){
//   var total_amount = 0.0, sale_tax = 0.0;
//   $('.amount').each(function(index, element){
//     if(element.textContent != '' && $(element).is(":visible") ){
//       total_amount += parseFloat(element.textContent)
//     }
//   });
//   sale_tax = $('#applicant_sale_tax').val()
//   if( sale_tax != ''){
//     total_amount += parseFloat(sale_tax)
//   }
//   // $('.total-amount').text(total_amount.toFixed(2))
//   if($('#product_details_form').length == 0){
//     $('#applicant_sale_total_amount').val(total_amount.toFixed(2))
//   }
//   $('#applicant_sale_total_amount').focusout();
//   $('#product_details').mouseover();
// }

window.termFinder = function(amount){
  var term = 0
  if(storeTerm > 0){
    return storeTerm;
  }
  $.each(emiTermList, function(index, list){
    if(list.max >= amount && list.min <= amount){
      term = list.term;
    }
  })
  return term
}

// window.paymentCalculate = function(){
//     if ($('#credit_rating').val() == 'fair' ){
//     $("#loan_amount option[value = '30,000 - 40,000'], option[value = '40,000 - 50,000'], option[value = '50,000 - 60,000'], option[value = '60,000 - 70,000'], option[value = '70,000 - 80,000'], option[value = '80,000 - 90,000'], option[value = '90,000 - 100,000']").prop('disabled', true);
//     $("#loan_amount option[value = '30,000 - 40,000'], option[value = '40,000 - 50,000'], option[value = '50,000 - 60,000'], option[value = '60,000 - 70,000'], option[value = '70,000 - 80,000'], option[value = '80,000 - 90,000'], option[value = '90,000 - 100,000']").hide()
//   }else{
//     $("#loan_amount option[value = '30,000 - 40,000'], option[value = '40,000 - 50,000'], option[value = '50,000 - 60,000'], option[value = '60,000 - 70,000'], option[value = '70,000 - 80,000'], option[value = '80,000 - 90,000'], option[value = '90,000 - 100,000']").prop('disabled', false);
//     $("#loan_amount option[value = '30,000 - 40,000'], option[value = '40,000 - 50,000'], option[value = '50,000 - 60,000'], option[value = '60,000 - 70,000'], option[value = '70,000 - 80,000'], option[value = '80,000 - 90,000'], option[value = '90,000 - 100,000']").show();
//   }
// }

// window.twoDecimalPoint = function(element){
//   $(element).val(parseFloat($(element).val()).toFixed(2));
// }
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lender-user"
export default class extends Controller {
  connect() {
    lenderUserValidator()
    $('.right-arrow').click(function(){
      $('.lender_user_table').animate( { scrollLeft: '+=100' }, 100);
    })

    $('.left-arrow').click(function(){
      $('.lender_user_table').animate( { scrollLeft: '-=100' }, 100);
    })

    $("#new_lender_user_id").validate({
      rules: {
        "lender_user[login_id]": {
          required: true,
          minlength: 6,
          blankSpace: true
        },
        "lender_user[first_name]": {
          required: true,
          minlength: 2,
          alphabetsnspace: true
        },
        "lender_user[last_name]": {
          required: true,
          minlength: 2,
          alphabetsnspace: true
        },
        "lender_user[email]": {
          required: true,
          emailregex: true
        },
        "lender_user[password]": {
          required: true,
          passwordregex: true
        },
        "lender_user[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "lender_user[login_id]": {
          required: "Please enter User ID.",
          minlength: 'User ID must be more than 6 character long.',
          blankSpace: 'Blank space not allowed.'
        },
        "lender_user[first_name]": {
          required: "Please enter first name.",
          minlength: 'Please enter at least 2 characters. Please use alphabets only.',
          alphabetsnspace: 'Please use alphabets only.'
        },
        "lender_user[last_name]": {
          required: "Please enter last name.",
          minlength: 'Please enter at least 2 characters. Please use alphabets only.',
          alphabetsnspace: 'Please use alphabets only.'
        },
        "lender_user[email]": {
          required: "Please enter email address.",
          emailregex: 'Please enter valid email address.'
        },
        "lender_user[password]": {
          required: "Please enter password.",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "lender_user[password_confirmation]": {
          required: "Please enter confirm password.",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      }
    });
    $("#edit_lender_id").validate({
      rules: {
        "lender_user[login_id]": {
          required: true,
          minlength: 6
        },
        "lender_user[first_name]": {
          required: true,
          alphabetsnspace: true,
          minlength: 2
        },
        "lender_user[last_name]": {
          required: true,
          alphabetsnspace: true,
          minlength: 2
        },
        "lender_user[email]": {
          required: true,
          emailregex: true
        },
        "lender_user[password]": {
          required: false,
          passwordregex: true
        },
        "lender_user[password_confirmation]": {
          required: false,
          passwordregex: true
        }
      },
      messages: {
        "lender_user[login_id]": {
          required: "Please enter User ID.",
          minlength: 'User ID must be more than 6 character long.'
        },
        "lender_user[first_name]": {
          required: "Please enter first name.",
          alphabetsnspace: 'Please enter at least 2 characters. Please use alphabets only.',
          minlength: 'Please enter at least 2 characters. Please use alphabets only.'
        },
        "lender_user[last_name]": {
          required: "Please enter last name.",
          alphabetsnspace: 'Please use alphabets only.',
          minlength: 'Please enter at least 2 characters. Please use alphabets only.'
        },
        "lender_user[email]": {
          required: "Please enter email address.",
          emailregex: 'Please enter valid email address.'
        },
        "lender_user[password]": {
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "lender_user[password_confirmation]": {
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      }
    });
  }
}

window.lenderUserValidator = function(){
  $.validator.addMethod("alphabetsnspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z]+$/.test(value);
  });
  $.validator.addMethod("emailregex", function(value, element) {
    return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
  });

  $.validator.addMethod("passwordregex", function(value, element) {
    return this.optional(element) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/.test(value);
  });

  $.validator.addMethod("blankSpace", function(value, element) {
   if(value.replaceAll(' ', '').length == 0){
      return false
    }else{
      return this.optional(element) || true
    }
  });
}
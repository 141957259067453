import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="member-user"
export default class extends Controller {
  connect() {
    $('#store_ids').select2()
    $('.modal').css('cssText','z-index: 1051 !important')

    $('.right-arrow').click(function(){
      $('.member_user_table').animate( { scrollLeft: '+=100' }, 100);
    })

    $('.left-arrow').click(function(){
      $('.member_user_table').animate( { scrollLeft: '-=100' }, 100);
    })

    $("#edit_member_id").validate({
      rules: {
        "member[login_id]": {
          required: true,
          minlength: 6
        },
        "member[name]": {
          required: true,
          fullname: true
        },
        "member[email]": {
          required: true,
          emailregex: true
        },
        "member[password]": {
          required: false,
          passwordregex: true
        },
        "member[password_confirmation]": {
          required: false,
          passwordregex: true
        },

      },
      messages: {
        "member[login_id]": {
          required: "Please enter User ID.",
          minlength: 'User ID must be more than 6 character long.'
        },
        "member[name]": {
          required: "Please enter full name.",
          fullname: "Please enter at least 2 characters. Create space between first name and last name."
        },
        "member[email]": {
          required: "Please enter email address.",
          emailregex: 'Please enter valid email address.'
        },
        "member[password]": {
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "member[password_confirmation]": {
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
      },
    });

     $("#new_member_id").validate({
      rules: {
        "member[login_id]": {
          required: true,
          minlength: 6
        },
        "member[name]": {
          required: true,
          fullname: true
        },
        "member[email]": {
          required: true,
          emailregex: true
        },
        "member[password]": {
          required: true,
          passwordregex: true
        },
        "member[password_confirmation]": {
          required: true,
          passwordregex: true
        },

      },
      messages: {
        "member[login_id]": {
          required: "Please enter User ID.",
          minlength: 'User ID must be more than 6 character long.'
        },
        "member[name]": {
          required: "Please enter full name.",
          fullname: "Please enter at least 2 characters. Create space between first name and last name."
        },
        "member[email]": {
          required: "Please enter email address.",
          emailregex: 'Please enter valid email address.'
        },
        "member[password]": {
          required: 'Please enter password.',
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "member[password_confirmation]": {
          required: 'Please enter confirm password.',
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },

      },

    });

    $.validator.addMethod("passwordregex", function(value, element) {
      return this.optional(element) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/.test(value);
    });

    $.validator.addMethod("emailregex", function(value, element) {
      return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
    });

    $.validator.addMethod("fullname", function(value, element) {
      return this.optional(element) || /^[A-Z][a-z-]+\s[A-Z][a-z-]+$/i.test(value);
    });
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setting-change-password"
export default class extends Controller {
  connect() {
    UserSetting()
    $("#change_password_form_lender_user").validate({
      rules: {
        "lender_user[password]": {
          required: true,
          passwordregex: true
        },
        "lender_user[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "lender_user[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "lender_user[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      },
    });
    $("#change_password_form_admin").validate({
      rules: {
        "admin_user[password]": {
          required: true,
          passwordregex: true
        },
        "admin_user[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "admin_user[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "admin_user[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      },
    });
    $("#change_password_form_member").validate({
      rules: {
        "member[password]": {
          required: true,
          passwordregex: true
        },
        "member[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "member[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "member[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      },
    })

  }

}

window.UserSetting = function(){
  $.validator.addMethod("alphabetsnspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z]+$/.test(value);
  });

  $.validator.addMethod("emailregex", function(value, element) {
    return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
  });
  $.validator.addMethod("passwordregex", function(value, element) {
    return this.optional(element) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{8,}$/.test(value);
  });
}

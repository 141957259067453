import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sweetpay-user-error"
export default class extends Controller {
  connect() {
    inputErrorLabel()
  }
}

window.inputErrorLabel = function() {
  var email = $('#flash_errors_box').data('email')
  var login_id =$('#flash_errors_box').data('login-id')
  var password = $('#flash_errors_box').data('password')
  var password_confirmation = $('#flash_errors_box').data('password-confirmation')
  var user = $('#flash_errors_box').data('user')
  if(email.length > 4){
    $(`input[name="${user}[email]"]`).next('label').remove()
    $(`input[name="${user}[email]"]`).after(errorLabel('email', email, user))
  }

  if(login_id.length > 4){
    $(`input[name="${user}[login_id]"]`).next('label').remove()
    $(`input[name="${user}[login_id]"]`).after(errorLabel('login_id', login_id, user))
  }

  if(password.length > 4){
    $(`input[name="${user}[password]"]`).next('label').remove()
    $(`input[name="${user}[password]"]`).after(errorLabel('password', password, user))
  }

  if(password_confirmation.length > 4){
    $(`input[name="${user}[password_confirmation]"]`).next('label').remove()
    $(`input[name="${user}[password_confirmation]"]`).after(errorLabel('password_confirmation', password_confirmation))
  }
}

window.errorLabel = function(type, message, user) {
  if(type == 'login_id'){
    message = message.replace('Login', 'User ID')
  }
  else{
    message = message
  }

  return `<label id="${user}_${type}-error" class="error" for="${user}_${type}"> ${message} </label>`
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="saved-filter"
export default class extends Controller {
  connect() {
    var filters = $('#filter_values').data('filter')
    if(filters != undefined){
        var multipleSelection = [
                                  'lender_ids[]', 'member_ids[]', 'loan_purpose[]', 'status_ids[]',
                                  'prescreen_filters[]', 'account_owner_ids[]', 'promotion_ids[]',
                                  'state_ids[]', 'sub_links'
                                ]

        $.each(multipleSelection, function(index, item){
          var data = filters[item]
          if(data != ''){
            $('select[name="' + item + '"').val(filters[item])
            $('select[name="' + item + '"').select2();
          }else{
            $('select[name="' + item + '"').val(null);
            $('select[name="' + item + '"').trigger('change');
          }
        })

        var inputTextValue = [
                              'app_date_from', 'app_date_to', 'rep_id', 'keyword',
                              'apr_range_to_id', 'apr_range_form_id', 'sale_completion_period_from_date',
                              'sale_completion_period_to_date', 'min_loan_amount_range_id',
                              'max_loan_amount_range_id'
                             ]

        var addColumnValue = [
                        'state_colunm', 'requested_amount', 'accepted_amount', 'funded_amount',
                        'apr_percent', 'funded_date', 'sale_completed', 'sale_completion_period',
                        'email', 'phone', 'city', 'zip_code', 'member_id', 'loan_purpose_column',
                        'offer_accepted_at', 'delivery_at', 'total_price', 'order_total', 'contact_date',
                        'contact_result', 'initial_status', 'sub_link', 'rep_id', 'app_status_date', 'funding_lender',
                        'sub_link_name', 'prequal_status', 'return_amount', 'return_date', 'adjusted_funded_amount',
                        'tier', 'offer_type', 'transaction_fee', 'transaction_fee_amount', 'socure_bank_account_status'
                     ]

        $.each(addColumnValue, function(index, item){
          if (filters["add_column_names[]"] != undefined) {
            var addColumnSelection = filters["add_column_names[]"].split(",")
            $('input[name="' + item + '"]').prop('checked', addColumnSelection.includes(item))
          } else {
            $('input[name="' + item + '"]').prop('checked', false)
          }
          var counts = 0;
          var message = '';
          $('.add_column_item input:checkbox').each(function(index, item){
            if(this.checked){
              counts  = $('.add_column_item input:checked').length
              message = counts < 1 ? 'Add column' : counts + " columns added"
              $('#add_column_btn').text(message)
            }else{
              message = counts < 1 ? 'Add column' : counts + " columns added"
              $('#add_column_btn').text(message)
            }

          })
        })

        $.each(inputTextValue, function(index, item){
          console.log(filters[item])
          $('input[name="' + item + '"]').val(filters[item])
        })

        $('input[value="' + filters['app_lender_wise'] + '"]').click()
        $('input[value="' + filters['date_wise'] + '"]').click()

        if(filters['app_date_from'] != undefined){
         $('#to-filter').prop('disabled', false)
        }
        else{
         $('#to-filter').prop('disabled',true)
        }

        $('input[name="show_returns"]').prop('checked', Boolean(filters['show_returns']))
        $('input[name="state_filter"]').prop('checked', Boolean(filters['state_filter']))
        $('input[name="promotion_filter"]').prop('checked',  Boolean(filters['promotion_filter']))
        $('input[name="accepted_apr_range_filter"]').prop('checked', Boolean(filters['accepted_apr_range_filter']))
        $('input[name="sale_completion_period_filter"]').prop('checked', Boolean(filters['sale_completion_period_filter']))
        $('input[name="requested_loan_amount_range_filter"]').prop('checked', Boolean(filters['requested_loan_amount_range_filter']))
        $('#more-filter-option input, #more-filter-option select').prop("disabled", !Boolean(filters['more_option']));

        if($('label[aria-expanded="true"]').length > 0 && !Boolean(filters['more_option'])) {
          $('.less-text:visible').click();
        }else if($('label[aria-expanded="true"]').length == 0 && Boolean(filters['more_option'])){
          $('.more-text:visible').click();
        }
        $('input[name="more_option"]').prop('checked', Boolean(filters['more_option']))

        if(Boolean(filters['show_returns'])){
          $('#date_wise_return_date_wise').parent().parent().parent().show();
        }else{
          $('#date_wise_return_date_wise').parent().parent().parent().hide();
        }

        $('input[name="apr_range_to_id"]').val(filters['apr_range_to_id'])

        if($('input[name="promotion_filter"]').is(':checked')){
          $('#promotion_ids').parent().parent().show()
        }else{
          $('#promotion_ids').parent().parent().hide()
        }

        if($('input[name="state_filter"]').is(':checked')){
          $('#state_ids').parent().parent().show()
        }else{
          $('#state_ids').parent().parent().hide()
        }

        if($('input[name="accepted_apr_range_filter"]').is(':checked')){
          $('#apr_range_form_id').parent().parent().show()
          $('#apr_range_to_id').parent().parent().show()
        }else{
          $('#apr_range_form_id').parent().parent().hide()
          $('#apr_range_to_id').parent().parent().hide()
        }

        if($('input[name="sale_completion_period_filter"]').is(':checked')){
          $('#from-filter-sale').parent().parent().show();
          $('#to-filter-sale').parent().parent().show()
        }else{
          $('#from-filter-sale').parent().parent().hide();
          $('#to-filter-sale').parent().parent().hide()
        }

        if($('input[name="requested_loan_amount_range_filter"]').is(':checked')){
          $('#max_loan_amount_range_id').parent().parent().show()
          $('#min_loan_amount_range_id').parent().parent().show()
        }else{
          $('#max_loan_amount_range_id').parent().parent().hide()
          $('#min_loan_amount_range_id').parent().parent().hide()
        }
    }
  }
}

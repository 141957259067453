import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard"
export default class extends Controller {
  connect() {
    $('#lender_filter_ids, .member_filter_ids, ids, lender_ids').select2({
      placeholder: "All"
    });
    // Loan Purpose Section
    $('.loan-purpose-card .data-loading').hide()
    $('.prescreen-card .data-loading').hide();
    $('.lender-wise-card .data-loading').hide();
    $('.result-card .data-loading').hide();
    $('.trends-card .data-loading').hide();

    $('.member-result-card .data-loading').hide();
    $('.member-trends-card .data-loading').hide();
    $('.member-prescreen-card .data-loading').hide();

    $('#results_for_form').submit(function(){
      // $('.loan-purpose-card .data-loading').show("").delay(3000).hide("");
      $('.loan-purpose-card .data-loading').show();
      setTimeout(function() { $(".loan-purpose-card .data-loading").hide(); }, 3000);
    })

    // Prescreen Section
    $('#date_wise_prescreen_filter_form').submit(function(){
      $('.prescreen-card .data-loading').show();
      setTimeout(function() { $(".prescreen-card .data-loading").hide(); }, 3000);
    });

    // Prescreen Section for Member
    $('#date_wise_prescreen_filter_form_member_dashboard').submit(function(){
      $('.member-prescreen-card .data-loading').show();
      setTimeout(function() { $(".member-prescreen-card .data-loading").hide(); }, 3000);
    });

    // Lender Wise Filter
    $('#lender_wise_result_form').submit(function(){
      $('.lender-wise-card .data-loading').show();
      setTimeout(function() { $(".lender-wise-card .data-loading").hide(); }, 3000);
    });

    // Main Filter
    $('#member_wise_filter_form').submit(function(){
      $(".result-card .data-loading").show();
      setTimeout(function() { $(".result-card .data-loading").hide(); }, 3000);
      $('.trends-card .data-loading').show()
      setTimeout(function() { $(".trends-card .data-loading").hide(); }, 3000);
      allOtherFilterSubmit()
      $('#lender_filter_ids, .member_filter_ids, ids, lender_ids').select2({
        placeholder: "All"
      });
    });

    // Main Filter for member
    $('#member_wise_filter').submit(function(){

      $('.member-result-card .data-loading').show();
      setTimeout(function() { $(".member-result-card .data-loading").hide(); }, 3000);

      $('.member-trends-card .data-loading').show();
      setTimeout(function() { $(".member-trends-card .data-loading").hide(); }, 3000);

      allOtherFilterForMemberSubmit()
    });

    $('#member_wise_filter_form button').click();
    $('#member_wise_filter button').click();
  }

  setPrescreenDaysFilter(){
    $('#date_wise_prescreen_filter_form button').click();
    $('.prescreen-card .data-loading').show();
    setTimeout(function() { $(".prescreen-card .data-loading").hide(); }, 3000);
  }

  setResultsForForm(){
    $('#results_for_form button').click();
    $('.loan-purpose-card .data-loading').show();
    setTimeout(function() { $(".loan-purpose-card .data-loading").hide(); }, 3000);
  }

  setLenderWiseResult(){
    $('#lender_wise_result_form button').click();
    $('.lender-wise-card .data-loading').show();
    setTimeout(function() { $(".lender-wise-card .data-loading").hide(); }, 3000);
  }

  setPrescreenDaysFilterForMember(event) {
    $('#date_wise_prescreen_filter_form_member_dashboard button').click();
    $('.member-prescreen-card .data-loading').show();
    setTimeout(function() { $(".member-prescreen-card .data-loading").hide(); }, 3000);
  }

  update_filter(event){
    var program_type = event.target.value
    var user = $(event.target).data('user')
    var url;
    
    console.log($(event.target).data('user'))
     
    if(program_type == ''){
      program_type = 'all'
    }

    if(user == 'Member'){
      url = `/member/dashboard/${program_type}/dashboard_program_type_filter`
    }else{
      url = `/admin/dashboard/${program_type}/dashboard_program_type_filter`
    }

    $.ajax({
      type: "get",
      url: url,
      data: {},
      contentType: "application/json",
      dataType: "json",
      success: function(res){
        console.log(res)
        // $("#access_token").text(res['access_token']);
        $("#lender_filter_ids option").remove();
        $("#store_filter_ids option").remove();

        $.each(res.lenders, function(index, item) {
          $("#lender_filter_ids").append(`<option value=${item[1]}>${item[0]}</option>`);
        });
        $.each(res.stores, function(index, item) {
          console.log(item)
          $("#store_filter_ids").append(`<option value=${item[1]}>${item[0]}</option>`);
        });

      }
    });
  }
}

window.allOtherFilterSubmit = function(){
  let storeFilterIds = $('#store_filter_ids').val()
  let lenderFilterIds = $('#lender_filter_ids').val()
  let programTypeFilter = $('#all_program_types').val()

  $('#lender_ids_prescreen').val(lenderFilterIds)
  $('#lender_ids_loan_purpose').val(lenderFilterIds)
  $('#lender_ids_lender_wise').val(lenderFilterIds)

  $('#store_ids_prescreen').val(storeFilterIds)
  $('#store_ids_loan_purpose').val(storeFilterIds)
  $('#store_ids_lender_wise').val(storeFilterIds)

  $('#program_types_prescreen').val(programTypeFilter)
  $('#program_types_purpose').val(programTypeFilter)
  $('#program_types_lender_wise').val(programTypeFilter)

  // Prescreen Section Button Submit
  $('#date_wise_prescreen_filter_form button').click();

  // Lender Wise Filter Button Submit
  $('#lender_wise_result_form button').click();

  // Loan Purpose Section Button Submit
  $('#results_for_form button').click();
}

window.allOtherFilterForMemberSubmit = function(){
  let storeFilterIds = $('#members_filter_ids').val()
  let lenderFilterIds = $('#lender_filter_ids').val()
  let programTypeFilter = $('#all_program_types').val()

  $('#lender_ids_prescreen').val(lenderFilterIds)

  $('#store_ids_prescreen').val(storeFilterIds)

  $('#program_types_prescreen').val(programTypeFilter)
  // Prescreen Section Button Submit
  $('#date_wise_prescreen_filter_form_member_dashboard button').click();
}

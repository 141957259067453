import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="communication-report"
export default class extends Controller {
  connect() {
    $('#member_ids, #lender_ids, #state_ids, #status_ids, #contact_result').select2({
      placeholder: "All"
    });
    $('#loading_data').hide()

    $('#from-filter, #to-filter').change(function(){
      if($(this).val() != ''){
        $(this).removeClass('error')
        $(this).next().remove()
      }
    });

    $(".fromDate").datepicker({
         format: 'mm-dd-yyyy',
         autoclose: true,
     }).on('changeDate', function (selected) {
         var minDate = new Date(selected.date.valueOf());
         $('.toDate').datepicker('setStartDate', minDate);
     });

    $(".toDate").datepicker({
         format: 'mm-dd-yyyy',
         autoclose: true,
    }).on('changeDate', function (selected) {
      var minDate = new Date(selected.date.valueOf());
      $('.fromDate').datepicker('setEndDate', minDate);
    });
  }
  submitForm(){
    if($('#communication_reports').valid())
      $('#loading_data').show()
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products--product-details-edit"
export default class extends Controller {
  connect() {

    $('.product_edit_btn').hide()

    $('#applicant_product_details_edit input').keyup(function(){
      validation_checker()
    });

    $('#product-edit-section').mouseover(function(){
      submitBtnEnableDisable()
      productRulesSet()
    });

    $('.product-detail-sections').mouseover(function(){
      submitBtnEnableDisable()
      productRulesSet()
    });
    $('.product_unit_price').keyup(function(){
      maxLimit($(this))
    });
    $('.product_unit_price').change(function() {
      twoDecimalPoint($(this))
      calculate($(this))
    });

    $('.product_quantity').change(function() {
      calculate($(this))
    });

    $('#applicant_sale_tax').keyup(function(){
      maxLimit($(this))
    });

    $('#applicant_sale_tax').change(function() {
      twoDecimalPoint($(this))
      calculate($(this))
    });

    var fixed_delivery_date = $('#delivery_at').data('date')
    if (fixed_delivery_date != ''){
      $('#applicant_delivery_at').datepicker({
        showOn: "button",
        autoclose: true,
        format: 'mm-dd-yyyy',
        todayHighlight: true,
        startDate: new Date(fixed_delivery_date),
        endDate: '+1Y'
      });
    }else{
      $('#applicant_delivery_at').datepicker({
        showOn: "button",
        autoclose: true,
        format: 'mm-dd-yyyy',
        todayHighlight: true,
        startDate: '1D',
        endDate: '+1Y'
      });
    }
  }
}

window.validation_checker = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs;
  documentUploadValidation();
  if($('#applicant_info').length == 1){
    myInputs = $('#applicant_info input:required, #applicant_info select:required, #applicant_info textarea:required').filter( ":visible" );
  }else{
    myInputs = $('input:required, select:required, textarea:required');
  }

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  totalInputs = myInputs.length

  if ( $('#product-info').length == 1 && $('.nested-fields').length == 0) {
    totalInputs += 1
  }

  if (inputsWithValues == totalInputs) {
    $(".submission").prop("disabled", false);
  } else {
    $(".submission").prop("disabled", true);
  }
}

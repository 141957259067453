import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--spi-pba-modules"
export default class extends Controller {
  connect() {
    $('.spi_pba_module_member_id').select2({
      minimumInputLength: 3,
      placeholder: "All",
      allowClear: true
    });

    $('.spi_pba_module_dropdowns  #member_id').change(function(){
      $('.spi_pba_button').click()
    })
    
    $('.spi_pba_module_dropdowns #pba_status').change(function(){
      $('.spi_pba_button').click()
    })

    var checkbox  =  $('#pba_denied')
    checkbox.on('click', function() {
      if($(this).is(':checked')) {
        console.log("block");
        $('.reason-for-denial-pba').removeClass('d-none');
        $('.reason-for-denial-pba').addClass('d-block');
      } else {
        console.log("none");
        $('.reason-for-denial-pba').removeClass('d-block');
        $('.reason-for-denial-pba').addClass('d-none');
      }
    });
  }

  PbaDeniedSubmit(){
    console.log('asdasd')
    if($('#pba_denied_form').valid()){
      $('.modal').hide()
      $('.modal-backdrop').remove()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="assign-promotion"
export default class extends Controller {
  connect() {
    $('#store_promotion_id').change(function(){
      if($(this).val() == ''){
        $('#store_redeem_days').val('')
        $('#store_redeem_days').prop('required', false)
      }else{
        $('#store_redeem_days').prop('required', true)
      }
    });
    $("#promotion_id").validate({
      rules: {
        "store[redeem_days]": {
          digits: true,
          min: 0,
          max: 365
        },
      },
      messages: {
        "store[redeem_days]": {
          digits: "The value is not an integer",
          min: "The redeem days must be 0-365.",
          max: "The redeem days must be 0-365."
        },
      },
    });
  }
}

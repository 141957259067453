import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--pymt-tnx"
export default class extends Controller {
  connect() {
    $('.tx_date').each(function(index, item) {
      var date = $(item).data('due-at')
      $(item).datepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        endDate: new Date(),
        startDate: new Date(date)
      });
    })

    $('.PenaltyPaidDate').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      endDate: new Date()
    })
    $('#new_penatly').validate()
  }
  pymtTxnSubmit(){
    console.log('connect pymtTxnSubmit')
    if($('.edit_pymt_txn').valid()){
      $('.modal').hide()
      $('.modal-backdrop').remove()
    }
  }
  PenaltypymtTxnSubmit(){
    if($('#new_penatly').valid()){
      $('.modal').hide()
      $('.modal-backdrop').remove()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-message"
export default class extends Controller {
  static values = {
    name: String
  }

  connect() {
    if(this.nameValue == 'settings') {
      setTimeout(function(){ $('.alert-time-out').hide() }, 5000);
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--vive-term-agreement"
export default class extends Controller {
  connect() {

    $(".app_agreement").click(function(){
      $('.app_agreement_check').prop('disabled', false)
    })
    $(".econsent_agreement").click(function(){
      $('.econsent_agreement_check').prop('disabled', false)
    })
    $(".condition_agreement").click(function(){
      $('.condition_agreement_check').prop('disabled', false)
    })

    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    $('.btn-submit').css("opacity", "0.6");
    $('.btn-submit').prop('disabled', true);
  }

  setOfferButtonDisplay(){
    $('.btn-submit').css("opacity", "0.6");
    $('.btn-submit').prop('disabled', true);
    var total = 0
    $('input').each(function(index, item){
      if(item.checked){ total += 1 }
    })
    if (total == 3) {
      $('.btn-submit').prop('disabled', false);
      $('.btn-submit').css("opacity", "1");
    }
    else{
      $('.btn-submit').prop('disabled', true);
      $('.btn-submit').css("opacity", "0.6");
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forget-password"
export default class extends Controller {
  connect() {
    passwordValidator()
    $("#reset_password_form_admin").validate({
      rules: {
        "admin_user[password]": {
          required: true,
          passwordregex: true
        },
        "admin_user[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "admin_user[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "admin_user[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      },
    })
    $("#change_password_form_member").validate({
      rules: {
        "member[password]": {
          required: true,
          passwordregex: true
        },
        "member[password_confirmation]": {
          required: true,
          passwordregex: true
        }
      },
      messages: {
        "member[password]": {
          required: "The password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        },
        "member[password_confirmation]": {
          required: "The confirm password is required and can\'t be empty",
          passwordregex: 'Passwords must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, a number, and a symbol.'
        }
      },
    })
  }
}

window.passwordValidator = function(){
  $.validator.addMethod("passwordregex", function(value, element) {
    return this.optional(element) || /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(value);
  });
}


let applicant_id, form_type, count;

function short_form_offer(id, repeat_count){
  applicant_id = id
  count        = repeat_count
  form_type = 'short_form_offer'
  call()
}

function get_offer(id,repeat_count){
  applicant_id = id
  count = repeat_count
  form_type = 'get_offer'
  get_offer_call()
}

function get_offer_for_form4(id,repeat_count){
  applicant_id = id
  count = repeat_count
  form_type = 'get_offer_for_form4'
  get_offer_for_form4_call()
}

function recall_for_form4(id,repeat_count){
  setTimeout(function(){
   get_offer_for_form4(id, repeat_count)
   console.log('count ===>' + repeat_count )
  },5000)
}


function recall_for_get_offer(id, repeat_count){
  setTimeout( function(){
   get_offer(id, repeat_count)
   console.log('count ===>' + repeat_count )
  }, 5000)
}

function recall_for_short(id, repeat_count){
  setTimeout( function(){
   short_form_offer(id, repeat_count)
   console.log('count ===>' + repeat_count )
  }, 5000);
}

function long_form_offer(id, repeat_count){
  applicant_id = id
  count        = repeat_count
  form_type = 'long_form_offer'
  call()
}

function recall_for_long(id, repeat_count){
  setTimeout( function(){
   long_form_offer(id, repeat_count)
   console.log('count ===>' + repeat_count )
  }, 5000);
}

function ficoScoreCheck(id, repeat_count){
  applicant_id = id
  count        = repeat_count
  form_type    = 'fico_score_check'
  setTimeout( function(){
    call();
  }, 5000);
}

function get_prequel(id, repeat_count){
  applicant_id = id
  count        = repeat_count
  form_type    = 'pre_qualified'
  setTimeout( function(){
    get_offer_call();
  }, 5000);
}

function ficoScoreCheckV3(id, repeat_count){
  applicant_id = id
  count        = repeat_count
  form_type    = 'fico_score_check'
  setTimeout( function(){
    get_offer_call();
  }, 5000);
}

function ficoScoreCheckPrequel(id, repeat_count){
  applicant_id = id
  count        = repeat_count
  form_type    = 'fico_score_check_for_prequel'
  setTimeout( function (){
    get_offer_call();
  }, 5000);
}



function call(){
  $.ajax({
    type: 'GET',
    url: '/applicant_requests/' + applicant_id + '/' + form_type + '?count=' + count,
    // url: '/v3/applicant_requests/' + applicant_id + '/' + form_type + '?count=' + count,
    success: function(data) {
      // console.log('--->')
    }
  });
}

function get_offer_call(){
  $.ajax({
    type: 'GET',
    url: '/v3/applicant_requests/' + applicant_id + '/' + form_type + '?count=' + count,
    success: function(data) {
      // console.log('--->')
    }
  });
}

function get_offer_for_form4_call(){
  $.ajax({
    type: 'GET',
    url: '/v3/applicant_requests/' + applicant_id + '/' + form_type + '?count=' + count,
    success: function(data){
    }
  });
}


export { short_form_offer, recall_for_short, long_form_offer, recall_for_long, ficoScoreCheck, ficoScoreCheckV3, get_offer, recall_for_get_offer, get_offer_for_form4, recall_for_form4, get_prequel, ficoScoreCheckPrequel };
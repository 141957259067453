// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap";
import "./controllers"
import jQuery from "jquery";
window.$ = jQuery
window.jQuery = jQuery
window.bootstrap = bootstrap
import "jquery-validation";
import 'datatables.net';
import * as select2 from "select2";
// window.customValidator = function (){customValidator()}

import "./v2/application";
import "./v2/form_validation";
import "./v2/consumer_agreement";
import "./v2/form_lib";
import "@nathanvda/cocoon";

// import "./v2/update_form_validation";
// import "./stylesheets/application.scss";
// import DataTable from "datatables.net-bs5";
// import dataTable from "datatables.net";
// window.dataTable = dataTable
$('table').dataTable({})

// $.ajaxSetup({
//   headers: {
//     'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
//   }
// });
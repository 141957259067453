import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--resident-details"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    residentDetailValidator()

    $('#resident_detail_v3').validate({
      rules: {
        'applicant[home_type]': {
          required: true
        },
        'applicant[form_of_house]':{
          required: true,
        },
        'applicant[monthly_housing_payment]': {
          required: true,
          amountregx: true,
          maxlength: 7
        },

       'applicant[time_at_address]': {
          required: true,
          // min: 0,
          // max: 99,
          // maxlength: 2
        },
        'applicant[time_at_address_month]': {
          required: true,
          // min: 0,
          // max: 12,
          // maxlength: 2
        },
         'applicant[previous_address]': {
          required: true,
          addressregx: true,
          poboxregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[previous_city]': {
          required: true,
          minlength: 2,
          cityregex: true,
          maxlength: 40
        },
        'applicant[previous_apt_unit]': {
          maxlength: 20,
          aptunit: true
        },
        'applicant[previous_state_id]': {
          required: true
        },
        'applicant[previous_zip]': {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        }
      },
      messages: {
        'applicant[home_type]': {
          required: 'Please select Rent/Own.'
        },
        'applicant[form_of_house]':{
          required: 'Please select form of house'
        },
        'applicant[monthly_housing_payment]': {
          required: 'Please enter monthly housing payment.',
          amountregx: 'Please enter monthly housing payment.',
          maxlength: 'Sorry, amount entered must be less than 100,000.'
        },
        'applicant[time_at_address]': {
          required: 'Please select time at address (in years).'
        },
        'applicant[time_at_address_month]': {
          required: 'Please select time at address (in months).',
        },
        'applicant[previous_address]': {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
        },
        'applicant[previous_city]': {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        'applicant[previous_apt_unit]': {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
        },
        'applicant[previous_state_id]': {
          required: 'Please select State.'
        },
        'applicant[previous_zip]': {
          required: 'Please enter zip code.',
          zipregex: 'Please enter valid zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.'
        }
      }
    });

    $("#co_resident_detail_v3").validate({
      rules: {
        'applicant[co_home_type]': {
          required: true
        },
        'applicant[co_form_of_house]':{
          required: true,
        },
        'applicant[co_monthly_housing_payment]': {
          required: true,
          amountregx: true,
          maxlength: 7
        },

       'applicant[co_time_at_address]': {
          required: true,
        },
        'applicant[co_time_at_address_month]': {
          required: true,
        },
         'applicant[co_previous_address]': {
          required: true,
          addressregx: true,
          poboxregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[co_previous_city]': {
          required: true,
          minlength: 2,
          cityregex: true,
          maxlength: 40
        },
        'applicant[co_previous_apt_unit]': {
          maxlength: 20,
          aptunit: true
        },
        'applicant[co_previous_state_id]': {
          required: true
        },
        'applicant[co_previous_zip]': {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        }
      },
      messages: {
        'applicant[co_home_type]': {
          required: 'Please select Rent/Own.'
        },
        'applicant[co_form_of_house]':{
          required: 'Please select form of house'
        },
        'applicant[co_monthly_housing_payment]': {
          required: 'Please enter monthly housing payment.',
          amountregx: 'Please enter monthly housing payment.',
          maxlength: 'Sorry, amount entered must be less than 100,000.'
        },
        'applicant[co_time_at_address]': {
          required: 'Please select time at address (in years).'
        },
        'applicant[co_time_at_address_month]': {
          required: 'Please select time at address (in months).',
        },
        'applicant[co_previous_address]': {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
        },
        'applicant[co_previous_city]': {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        'applicant[co_previous_apt_unit]': {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only letters(a-z), numbers, period(.), hyphen (-) & hash (#) are allowed.'
        },
        'applicant[co_previous_state_id]': {
          required: 'Please select State.'
        },
        'applicant[co_previous_zip]': {
          required: 'Please enter zip code.',
          zipregex: 'Please enter valid zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.'
        }
      }
    });

    submitBtnEnableDisable();

    $('input').keyup(function(){
      submitBtnEnableDisable();
    });

    $('select').change(function(){
      submitBtnEnableDisable();
    });

    $('input[name="applicant[ssn]"]').keyup(function(){
      addDashes($(this))
    });

    // $('#applicant_monthly_housing_payment, #applicant_co_monthly_housing_payment').keyup(function(){
    //   numberFormatter($(this))
    // })

    $('#co_resident_detail_v3, #resident_detail_v3').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });


    var event = new Event('change');
    if($('#applicant_time_at_address_month').length > 0){
      $('#applicant_time_at_address_month')[0].dispatchEvent(event);
    }

    if($('#applicant_co_time_at_address_month').length > 0){
      $('#applicant_co_time_at_address_month')[0].dispatchEvent(event);
    }

  }

  setTimeAtAddressAndMonth(){

    let time_at_address_in_year = $('#applicant_time_at_address').val()
    let time_at_address_in_month = $('#applicant_time_at_address_month').val()
    if (time_at_address_in_month.length > 0 && time_at_address_in_year.length > 0 && time_at_address_in_year <= 0 && time_at_address_in_month >= 0){
      $('#previous_address').show()
      $('#applicant_previous_address').attr('required', true);
      $('#applicant_previous_city').attr('required', true);
      $('#applicant_previous_state_id').attr('required', true);
      $('#applicant_previous_zip').attr('required', true);

    }else{
      $('#previous_address').hide()
      $('#applicant_previous_address').attr('required', false);
      $('#applicant_previous_city').attr('required', false);
      $('#applicant_previous_state_id').attr('required', false);
      $('#applicant_previous_zip').attr('required', false);
    }

    if(time_at_address_in_year.length > 0 && time_at_address_in_year > 0){
      $('#applicant_time_at_address_month').rules('add', {
        min: 0
      });
    }else{
      $('#applicant_time_at_address_month').rules('add', {
        min: 1,
        messages: {
          min: 'Sorry, months and years both can not be zero.'
        }
      });
    }

  }

  setCoTimeAtAddressAndMonth(){
    let co_time_at_address_in_year = $('#applicant_co_time_at_address').val()
    let co_time_at_address_in_month = $('#applicant_co_time_at_address_month').val()
    if (co_time_at_address_in_month.length > 0 && co_time_at_address_in_year.length > 0 && co_time_at_address_in_year <= 0 && co_time_at_address_in_month >= 0){
      $('#co_previous_address').show()
      $('#applicant_co_previous_address').attr('required', true);
      $('#applicant_co_previous_city').attr('required', true);
      $('#applicant_co_previous_state_id').attr('required', true);
      $('#applicant_co_previous_zip').attr('required', true);
    }else{
      $('#co_previous_address').hide()
      $('#applicant_co_previous_address').attr('required', false);
      $('#applicant_co_previous_city').attr('required', false);
      $('#applicant_co_previous_state_id').attr('required', false);
      $('#applicant_co_previous_zip').attr('required', false);
    }

    if(co_time_at_address_in_month.length > 0  && co_time_at_address_in_year > 0){
      $('#applicant_co_time_at_address_month').rules('add', {
        min: 0
      });
    }else{
      $('#applicant_co_time_at_address_month').rules('add', {
        min: 1,
        messages: {
          min: 'Sorry, months and years both can not be zero.'
        }
      });
    }
  }
}

window.residentDetailValidator = function() {
  $.validator.addMethod("amountregx", function(value, element) {
    return this.optional(element) || /^[0-9,]+$/.test(value);
  });
  $.validator.addMethod("poboxregex", function(value, element) {
    return this.optional(element) || !/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box[0-9]{0,10})\b/i.test(value);
  });
  $.validator.addMethod("addressregx", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 .,-]+$/.test(value);
  });
  $.validator.addMethod("cityregex", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+(?:[- ][a-zA-Z ]+)*$/.test(value);
  });
  $.validator.addMethod("zipregex", function(value, element) {
    return this.optional(element) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  });
}

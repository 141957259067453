import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--applicant-filter-save"
export default class extends Controller {
  connect() {
    $('#applicant_filter_save_filters_exclude_member_ids, #applicant_filter_save_filters_include_member_ids').select2({
      placeholder: "All",
      allowClear: true,
      minimumInputLength: 3
    });

    $('#applicant_filter_save_filters_program_type_id').select2({
      placeholder: "All",
      allowClear: true
    })

    $('#applicant_filter_save_filters_lender_ids').select2({
      placeholder: "All",
      allowClear: true,
    });

    $('#applicant_filter_save_filters_agent_ids').select2({
      placeholder: "All",
      allowClear: true,
    });

    $('.modal').css('cssText','z-index: 1051 !important')
    $('.member_checked').click(function() {
      $('.member_checked').not(this).prop('checked', false);
    });
    $('#new_applicant_filter_form').validate()

    if($('#applicant_filter_save_filters_radio_filter_completed_applicants').is(':checked')){
      $('#applicant_filter_save_filters_prequal_offers').parent().parent().parent().parent().show()
    }else{
      $('#applicant_filter_save_filters_prequal_offers').parent().parent().parent().parent().hide()
    }

    $('input[type=radio]').change(function() {
      if($('#applicant_filter_save_filters_radio_filter_completed_applicants').is(':checked')){
        $('#applicant_filter_save_filters_prequal_offers').parent().parent().parent().parent().show()
      }else{
        $('#applicant_filter_save_filters_prequal_offers').parent().parent().parent().parent().hide()
      }
    });
   
    if($('.program_type_checkbox input').is(':checked')){
      $('#applicant_filter_save_filters_program_type_id').parent().parent().show()
    }
    else{
      $('#applicant_filter_save_filters_program_type_id').parent().parent().hide()
    }

    if($('.include_member_checkbox input').is(':checked')){
      $('#applicant_filter_save_filters_include_member_ids').parent().parent().show()
      $('#applicant_filter_save_filters_exclude_member_ids').parent().parent().hide()
    }

    if($('.exclude_checkbox input').is(':checked')){
      $('#applicant_filter_save_filters_exclude_member_ids').parent().parent().show()
      $('#applicant_filter_save_filters_include_member_ids').parent().parent().hide()
    }

    if($('.lender_checkbox input').is(':checked')){
      $('#applicant_filter_save_filters_lender_ids').parent().parent().show()
    }
    else{
      $('#applicant_filter_save_filters_lender_ids').parent().parent().hide()
    }

  }

  program_type_dropdown(event){
    var element = event.currentTarget
    if(element.checked){
      $('#applicant_filter_save_filters_program_type_id').parent().parent().show()
    }
    else{
      $('#applicant_filter_save_filters_program_type_id').parent().parent().hide()
    }
  }

  include_member_dropdown(event){
    var element = event.currentTarget
    if(element.checked){
      $('#applicant_filter_save_filters_include_member_ids').parent().parent().show()
      $('#applicant_filter_save_filters_exclude_member_ids').parent().parent().hide()
    }
    else{
      $('#applicant_filter_save_filters_include_member_ids').parent().parent().hide()
      $('#applicant_filter_save_filters_exclude_member_ids').parent().parent().hide()
    }
  }

  exclude_member_dropdown(event){
    var element = event.currentTarget
    if(element.checked){
        $('#applicant_filter_save_filters_exclude_member_ids').parent().parent().show()
        $('#applicant_filter_save_filters_include_member_ids').parent().parent().hide()
    }
    else{
      $('#applicant_filter_save_filters_exclude_member_ids').parent().parent().hide()
      $('#applicant_filter_save_filters_include_member_ids').parent().parent().hide()
    }
  }

  agent_dropdown(event){
    var element = event.currentTarget
    if(element.checked){
      $('#applicant_filter_save_filters_agent_ids').parent().parent().show()
    }
    else{
      $('#applicant_filter_save_filters_agent_ids').parent().parent().hide()
    } 
  }  

  lender_dropdown(event){
    var element = event.currentTarget
    if(element.checked){
      $('#applicant_filter_save_filters_lender_ids').parent().parent().show()
    }
    else{
      $('#applicant_filter_save_filters_lender_ids').parent().parent().hide()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="error-log"
export default class extends Controller {
  connect() {
    $('#applicant_wise_interval').change(function(){
      $('#applicant_wise_interval button').click();
    });

    $('#lender_wise_interval').change(function(){
      $('#lender_wise_interval button').click();
    });

    $('#search_applicant_by_number').change(function(){
      $('#search_applicant_by_number button').click();
    });

    $('#search_applicant_by_name').change(function(){
      $('#search_applicant_by_name button').click();
    });
    $('#applicant_wise_table').DataTable();
  }
}

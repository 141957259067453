import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products--buying-product"
export default class extends Controller {
  connect() {
    $('#accept_payment_plan_form').validate({
      rules: {
        'applicant[product_discription]': {
          required: true,
          minlength: 2,
          maxlength: 100
        }
      },
      messages: {
        'applicant[product_discription]': {
          required: 'Please enter product description.',
          minlength: 'The product description must be between 2 and 100 characters long.',
          maxlength: 'The product description must be between 2 and 100 characters long.'
        }
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--job-details"
export default class extends Controller {
  connect() {
    jobDetailValidator()
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    $('#job_detail_v3').validate({
      rules: {
        'applicant[income_type]': {
          required: true
        },
        'applicant[year_at_job]': {
          required: true,
        },
        'applicant[previous_year_at_job]': {
          required: true,
          previous_year: true,
        },
        'applicant[employement_status]': {
          required: true
        },
        'applicant[employer_name]':{
          required: true,
          alphabetswithspace: true,
          blankSpace: true
        },
        'applicant[employer_phone]':{
          required: true,
          maxlength: 12,
          minlength:12,
          phoneregex: true
        },
        'applicant[payment_frequency]': {
          required: true
        },
        'applicant[auto_payment_method]':{
          required: true
        },
        "applicant[next_payment_date]": {
          required: true,
          dateregex: true
        }
      },
      messages: {
        'applicant[income_type]': {
          required: 'Please select income type salary/self employement.'
        },
        'applicant[year_at_job]': {
          required: 'Please enter years at job.'
        },
        'applicant[previous_year_at_job]': {
          required: 'Please enter Previous years at job.',
          previous_year: 'Please select at least 1 year.',
        },
        'applicant[employement_status]': {
          required: 'Please select employement status.'
        },
        'applicant[employer_name]': {
          required: 'Please enter Employer Name.',
          alphabetswithspace: 'Sorry, only alphabets are allowed',
          blankSpace: 'Blank space not allowed'
        },
        'applicant[employer_phone]': {
          required: 'Please enter Employer Phone.',
          minlength: 'Your employer phone number must be 10 numeric digits only.',
          maxlength: 'Your employer phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid employer phone.'
        },
        'applicant[payment_frequency]': {
          required: 'Please select your pay frequency.'
        },
        'applicant[auto_payment_method]': {
          required: 'Please select payment account type.'
        },
        "applicant[next_payment_date]": {
          required: "Please select next payment date.",
          dateregex: 'Next Payment date must be in mm/dd//yyyy format.'
        }
      }
    });

    $('#co_job_detail_v3').validate({
      rules: {
        'applicant[co_income_type]': {
          required: true
        },
        'applicant[co_year_at_job]': {
          required: true,
          min: 0,
          max: 99,
          maxlength: 2
        },
        'applicant[co_employement_status]': {
          required: true
        },
        'applicant[co_employer_name]':{
          required: true,
          alphabetswithspace: true,
          blankSpace: true
        },
        'applicant[employer_phone]': {
          required: 'Please enter Employer Phone.',
          minlength: 'Your employer phone number must be 10 numeric digits only.',
          maxlength: 'Your employer phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid employer phone.',
        },
        'applicant[co_employer_phone]':{
          required: true,
          maxlength: 12,
          minlength:12,
          phoneregex: true,
        },
        'applicant[co_payment_frequency]': {
          required: true
        },
        'applicant[co_previous_year_at_job]': {
          required: true,
          co_previous_year: true,
        },
        'applicant[co_auto_payment_method]':{
          required: true
        }
      },
      messages: {
        'applicant[co_income_type]': {
          required: 'Please select income type salary/self employement.'
        },
        'applicant[co_year_at_job]': {
          required: 'Please enter years at job.',
          min: 'Sorry, please enter years at job between 0 to 99.',
          max: 'Sorry, please enter years at job between 0 to 99.',
          maxlength: 'Sorry, please enter years at job between 0 to 99.',
        },
        'applicant[co_employement_status]': {
          required: 'Please select employement status.'
        },
        'applicant[co_employer_name]': {
          required: 'Please enter Employer Name.',
          alphabetswithspace: 'Sorry, only alphabets are allowed.',
          blankSpace: 'Blank space not allowed.'
        },

        'applicant[co_payment_frequency]': {
          required: 'Please select your pay frequency.'
        },
        'applicant[co_previous_year_at_job]': {
          required: 'Please enter Previous years at job.',
          co_previous_year: 'Please select at least 1 year.',
        },
        'applicant[co_auto_payment_method]':{
          required: 'Please select auto payment method.'
        }
      }
    });

    submitBtnEnableDisable();
    $('input, select').keyup(function(){
      submitBtnEnableDisable();
    })

    $('input, select').change(function(){
      submitBtnEnableDisable();
    })

    $('#applicant_savings, #applicant_checking').change(function() {
    });

    $('#applicant_payment_frequency').change(function() {
      nextPaymentDateLimit($(this))
    });


    $('#applicant_employer_phone').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_co_employer_phone').keyup(function(){
      addDashes($(this))
    })

    $('#applicant_next_payment_date').keyup(function(){
      addSlash($(this))
    })

    $('#co_job_detail_v3, #job_detail_v3').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });
    applyDatePickerNextPaymentDate()
  }

  setAccountType() {
    hideShowAccountType();
  }

  setJobAtYearByEmpStatus(){
    jobAtYearDynamicShow();
    PreviousjobAtYearDynamicShow();
  }

  setCoJobAtYearByEmpStatus() {
    CojobAtYearDynamicShow();
    CoPreviousjobAtYearDynamicShow();
  }

  setPreviousJobAtYear(){
    PreviousjobAtYearDynamicShow()
  }

  setCoPreviousJobAtYear(){
    CoPreviousjobAtYearDynamicShow()
  }

}

window.hideShowAccountType = function(){
    var listDisplay = { 'debit_card': 'Debit Card', 'credit_card': 'Credit Card', 'savings': 'Savings', 'checking': 'Checking' }
    var list = ['debit_card', 'credit_card']

    if($('#applicant_checking').is(":checked")){
      list.push('checking')
    }

    if($('#applicant_savings').is(":checked")){
      list.push('savings')
    }

    $("#applicant_auto_payment_method").html('');
    $("#applicant_auto_payment_method").append('<option value="">Select</option>');
    $.each(list, function(index, item){
      $("#applicant_auto_payment_method").append( $('<option>', { value: item, text: listDisplay[item] }))
    });
}

window.jobDetailValidator = function() {
  $.validator.addMethod("phoneregex", function(value, element) {
    return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
  });

  $.validator.addMethod("previous_year", function(value, element) {
    var current_year = $('#applicant_year_at_job').val();
    return this.optional(element) || (current_year < 3 && value > 0);
  });

  $.validator.addMethod("co_previous_year", function(value, element) {
    var current_year = $('#applicant_co_year_at_job').val();
    return this.optional(element) || (current_year < 3 && value > 0);
  });

  $.validator.addMethod("alphabetswithspace", function(value, element) {
    return this.optional(element) || /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/.test(value);
  });

  $.validator.addMethod("blankSpace", function(value, element) {
    if(value.replaceAll(' ', '').length == 0){
      return false
    }else{
      return this.optional(element) || true
    }
  });

  $.validator.addMethod("dateregex", function(value, element) {
    return this.optional(element) || /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  });

}

window.PreviousjobAtYearDynamicShow = function() {
  let year_at_job = $('#applicant_year_at_job').val()
  let year_at_job_visible = $('#applicant_year_at_job:visible').length
  if(year_at_job != undefined && year_at_job.length > 0 && year_at_job < 3 && year_at_job_visible > 0){
      $('#applicant_previous_year_at_job').parent().parent().show()
      // $('#app`licant_previous_year_at_job').val('')
    }else{
      $('#applicant_previous_year_at_job').parent().parent().hide()
      // $('#app`licant_previous_year_at_job').val('')
    }
}

window.CoPreviousjobAtYearDynamicShow = function() {
  let co_year_at_job = $('#applicant_co_year_at_job').val()
  let co_year_at_job_visible = $('#applicant_co_year_at_job:visible').length
  if(co_year_at_job != undefined && co_year_at_job.length > 0 && co_year_at_job < 3 && co_year_at_job_visible > 0){
      $('#applicant_co_previous_year_at_job').parent().parent().show()
    }else{
      $('#applicant_co_previous_year_at_job').parent().parent().hide()
    }
}

window.jobAtYearDynamicShow = function(){
  var incomeSource = $('#applicant_employement_status').val()
  if(incomeSource == 'employed' || incomeSource == 'self_employed' || incomeSource == 'military'){
    $('#applicant_year_at_job').parent().parent().show()
    $('#applicant_year_at_job').val('')

    if(incomeSource == 'employed' || incomeSource == 'self_employed' || incomeSource == 'military') {
      $('#applicant_employer_name').parent().parent().show()
      $('#applicant_employer_name').val('')
      $('#applicant_employer_name').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Name.'
        }
      });
      $('#applicant_employer_name').attr('required', true)
      $('#applicant_employer_phone').parent().parent().show()
      $('#applicant_employer_phone').val('')
      $('#applicant_employer_phone').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Phone.'
        }
      });
      $('#applicant_employer_phone').attr('required', true)
    }
    else{
      $('#applicant_employer_name').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_employer_name').attr('required', false)
      $('#applicant_employer_name').parent().parent().hide()

      $('#applicant_employer_phone').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_employer_phone').attr('required', false)
      $('#applicant_employer_phone').parent().parent().hide()
    }
  }
  else{
    $('#applicant_year_at_job').val(0)
    $('#applicant_year_at_job').parent().parent().hide()
    $('#applicant_employer_name').parent().parent().hide()

    $('#applicant_employer_name').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_employer_name').attr('required', false)
    $('#applicant_employer_name').parent().parent().hide()


    $('#applicant_employer_phone').parent().parent().hide()
    $('#applicant_employer_phone').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_employer_phone').attr('required', false)
    $('#applicant_employer_phone').parent().parent().hide()
  }
}
window.CojobAtYearDynamicShow = function(){
  let incomeSource = $('#applicant_co_employement_status').val()
  if(incomeSource == 'co_employed' || incomeSource == 'co_self_employed' || incomeSource == 'co_military'){
    $('#applicant_co_year_at_job').parent().parent().show()
    $('#applicant_co_year_at_job').val('')

    if(incomeSource == 'co_employed' || incomeSource == 'co_self_employed' || incomeSource == 'co_military') {
      $('#applicant_co_employer_name').parent().parent().show()
      $('#applicant_co_employer_name').val('')

      $('#applicant_co_employer_name').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Name.'
        }
      });
      $('#applicant_co_employer_name').attr('required', true)

      $('#applicant_co_employer_phone').parent().parent().show()
      $('#applicant_co_employer_phone').val('')

      $('#applicant_co_employer_phone').rules('add', {
        required: true,
        messages: {
          required: 'Please enter Employer Phone.'
        }
      });
      $('#applicant_co_employer_phone').attr('required', true)
    }
    else{
      $('#applicant_co_employer_name').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_co_employer_name').attr('required', false)
      $('#applicant_co_employer_name').parent().parent().hide()

      $('#applicant_co_employer_phone').rules('add', {
        required: false,
        messages: {
          required: ''
        }
      });
      $('#applicant_co_employer_phone').attr('required', false)
      $('#applicant_co_employer_phone').parent().parent().hide()
    }
  }
  else{
    $('#applicant_co_year_at_job').val(0)
    $('#applicant_co_year_at_job').parent().parent().hide()
    $('#applicant_co_employer_name').parent().parent().hide()

    $('#applicant_co_employer_name').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_co_employer_name').attr('required', false)
    $('#applicant_co_employer_name').parent().parent().hide()

    $('#applicant_co_employer_phone').rules('add', {
      required: false,
      messages: {
        required: ''
      }
    });
    $('#applicant_co_employer_phone').attr('required', false)
    $('#applicant_co_employer_phone').parent().parent().hide()
  }
}

window.applyDatePickerNextPaymentDate =  function(){
  $('#applicant_next_payment_date').datepicker({
    format: 'mm/dd/yyyy',
    autoclose: true,
    todayHighlight: true,
    startDate: '+1D',
    endDate: '+7D'
  });
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stip-upload"
export default class extends Controller {
  connect() {
    console.log('connect')
    $('.document_upload_form input').change(function(){validation_checker()})
    $('.validation_checker_on').mouseover(function(){validation_checker()})
    if($('#stip_form').length > 0){
      referenceDocumentFormValidation();
      $('#stip input').keyup(function() {
        validation_checker()
      })
    }

    $('.offer-control').on('click', function() {
      $('.document_upload_form input').change(function(){validation_checker()})
      $('.validation_checker_on').mouseover(function(){validation_checker()})

      $('.document_upload_file').change(function(){
        var file_name = $(this).get(0).files[0].name
        $(this).prev().text(file_name)
      });
    });
  }

  documentUpload(event){
    validation_checker_for_stips()
  }

  validationChecker(event){
    validation_checker_for_stips()
  }

  documentUpload(event){
    var element = event.currentTarget
    var file_name = $(element).get(0).files[0].name
    $(element).prev().text(file_name)
  }

  setFileName(event){
    console.log('ref')
    // var fileName = event.currentTarget.files[0].name;
    // $('.reference_id').html(fileName);
    var element = event.currentTarget
    var file_name = $(element).get(0).files[0].name
    console.log(file_name)
    $(element).prev().text(file_name)
  }

  PictureFileName(event){
    console.log('pic id')
    var element = event.currentTarget
    var file_name = $(element).get(0).files[0].name
    console.log(file_name)
    $(element).prev().text(file_name)

    // var fileName = event.currentTarget.files[0].name;
    // $('.picture_id_stip').html(fileName);
  }

  displayTable(event){
    $('#dtm_stip_form').submit(function(){
    $(".offer_loading").css('display', 'table')})
    setTimeout(function() { $(".offer_loading").hide(); }, 3000)
  }
}

window.referenceDocumentFormValidation = function(){
  if($('#pri_number_of_reference').length > 0){
    var numberOfReference = $('#pri_number_of_reference').val();
    referenceDocumentFormValidationByType('pri', numberOfReference)
  }

  if($('#co_number_of_reference').length > 0){
    var numberOfReference = $('#co_number_of_reference').val();
    referenceDocumentFormValidationByType('co', numberOfReference)
  }
}

window.referenceDocumentFormValidationByType = function(type, numberOfReference){
  var numberOfReference = parseInt(numberOfReference)
  if (numberOfReference != NaN && numberOfReference > 0){
    for(var i = 0; i < numberOfReference; i++){
      $('#' + type + '_reference_first_name_' + i).rules('add', {
        required: true,
        alphabetsnspace: true,
        minlength: 2,
        maxlength: 40,
        messages: {
          alphabetsnspace: 'Please use alphabets only.',
          required: 'Please enter first name.',
          minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
        }
      });

      $('#' + type + '_reference_last_name_' + i).rules('add', {
        required: true,
        lastNameValidation: true,
        minlength: 2,
        maxlength: 40,
        messages: {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter last name.',
          minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
        }
      });

      $('#' + type + '_reference_phone_number_' + i).rules('add', {
        required: true,
        minlength: 12,
        maxlength: 12,
        phoneregex: true,
        messages: {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        }
      });
    }
  }

  $.validator.addMethod("alphabetsnspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z]+$/.test(value);
  });

  $.validator.addMethod("lastNameValidation", function(value, element) {
    return this.optional(element) || /^([a-zA-Z'-]+\s)*[a-zA-Z'-]+$/.test(value);
  });

  $.validator.addMethod("phoneregex", function(value, element) {
    return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
  });

}

window.validation_checker_for_stips = function(){
  var inputsWithValues = 0, totalInputs = 0;
  var myInputs         = $('input:required, select:required');
  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        //$(this).prev().prev().css('color', '#3c7643')
      }
      else{
        //$(this).prev().prev().css('color', '#a94442')
      }
    }
  });

  totalInputs = myInputs.length
  if($('#consent-check-box').length == 1){
    totalInputs += 1
    inputsWithValues += $('#consent-check-box:checked').length == 1 ? 1 : 0
  }

  if ( $('#product_details').length == 1 && $('.nested-fields').length == 0) {
    totalInputs += 1
  }

  if (inputsWithValues == totalInputs) {
    $(".btn-continue").prop("disabled", false);
  } else {
    $(".btn-continue").prop("disabled", true);
  }
}
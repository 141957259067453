import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="consumer-agreement"
export default class extends Controller {
  connect() {
    consumerAgreement();

    setTimeout(function() {
      window.location.reload();
    }, 600000);

    $('#terms_and_codition_box').scroll(function(){
      if($('#terms_and_codition_box').prop('scrollTop') + $('#terms_and_codition_box').prop('offsetHeight') >= $('#terms_and_codition_box').prop('scrollHeight')){
        $('#agreement_document_read').val('marked_read')
        validationCheckerConsumerAgreement()
      }
    });

    $('#bank_name, #routing_number, #account_number, #card_number, #card_expiry, #cvv').keyup(function(){
      validationCheckerConsumerAgreement();
      // paymentMethodValidationChecker()
    })



    $('#agreement_check_box').change(function(){
      if($('#agreement_check_box').is(':checked')){
        $('#consumer_agreement_btn').prop('disabled', false)
      }
      else{
        $('#consumer_agreement_btn').prop('disabled', true)
      }
      validationCheckerConsumerAgreement()
    });

    $('input[name="card_number"]').keyup(function(){
      addDashesCard($(this))
    })
    
    if($('#agreement_form').length == 1){
      $('#agreement_form').validate({
        rules: {
          'routing_number': {
            minlength: 9,
            maxlength: 9,
            required: true,
            digits: true,
            allZero: true
          },
          'account_number': {
            required: true,
            digits: true,
            minlength: 6,
            maxlength: 25,
            allZero: true
          },
          'bank_name':{
            maxlength: 256,
            required: true,
            blankSpace: true,
            alphabetWithSplSym: true
          },
          "card_number": {
            required: true,
            cardNumberRegex: true,
            minlength: 19,
            maxlength: 19
          },
          "cvv": {
            required: true,
            digits: true,
            minlength: 3,
            maxlength: 3
          }
        },
        messages: {
          'routing_number': {
            minlength: 'Sorry, routing number must be 9 digits',
            maxlength: 'Sorry, routing number must be 9 digits',
            digits: 'Sorry, only numbers are allowed.',
            allZero: 'Sorry, number is not valid.'

          },
          'account_number': {
            maxlength: "Sorry, account number must be between 6 and 25 digits.",
            minlength: "Sorry, account number must be between 6 and 25 digits.",
            digits: 'Sorry, only numbers are allowed.',
            allZero: 'Sorry, number is not valid.'
          },
          'bank_name':{
            required: 'Please enter bank name.',
            maxlength: "Sorry, bank name must be less then 256 words.",
            blankSpace: 'Blank space not allowed.',
            alphabetWithSplSym: 'Please enter valid bank name.'
          },
          "card_number": {
            required: "Please enter debit card number.",
            cardNumberRegex: "Please enter a valid debit card number.",
            minlength: 'Sorry, debit card must be 16 digits.',
            maxlength: 'Sorry, debit card must be 16 digits.'
          },
          "cvv": {
            required: 'Please enter vaild CVV number.',
            digits: 'Sorry, only numbers are allowed.',
            maxlength: "Sorry, CVV number must be 3 digits.",
            minlength: "Sorry, CVV number must be 3 digits."
          }
        }
      });
    }
  }

  aggreementChecked(event){
    if($('#agreement_check_box').is(':checked')){
      $('#consumer_agreement_btn').prop('disabled', false)
    }
    else{
      $('#consumer_agreement_btn').prop('disabled', true)
    }
    validationCheckerConsumerAgreement()
  }
}

// window.paymentMethodValidationChecker = function(){
//   let inputsWithValues = 0, totalInputs = 0;
//   let myInputs       =  $('input:visible[required], select:visible[required]');
  // console.log(myInputs)
  // myInputs.each(function(e) {
  //   if ($(this).val()) {
  //     if($(this).valid()){
  //       inputsWithValues += 1;
  //       $(this).prev().prev().css('color', '#3c7643')
  //     }
  //     else{
  //       $(this).prev().prev().css('color', '#a94442') 
  //     }
  //   }
  // });

//   totalInputs = myInputs.length

//   console.log(inputsWithValues)

//   if (inputsWithValues == totalInputs) {
//     $("#payment_method_btn").prop("disabled", false);
//   } else {
//     $("#payment_method_btn").prop("disabled", true);
//   }
// }

window.validationCheckerConsumerAgreement = function(){
  let inputsWithValues = 0, totalInputs = 0;
  let myInputs       =  $('input:visible[required], select:visible[required]');

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442') 
      }
    }
  });

  totalInputs = myInputs.length
  if($('#agreement_check_box').length == 1){
    totalInputs += 1
    inputsWithValues += $('#agreement_check_box:checked').length == 1 ? 1 : 0
  }

  totalInputs += 1
  if($('#agreement_document_read').val().length > 0){
    inputsWithValues += 1
  }

  if (inputsWithValues == totalInputs) {
    $("#consumer_agreement_btn").prop("disabled", false);
  } else {
    $("#consumer_agreement_btn").prop("disabled", true);
  }
}

window.consumerAgreement = function(){
  $.validator.addMethod("allZero", function(value, element) {
    return this.optional(element) || !(! + value);
  });

  $.validator.addMethod("cardNumberRegex", function(value, element) {
    return this.optional(element) || /^\d{4}-\d{4}-\d{4}-\d{4}$/.test(value);
  });

  $.validator.addMethod("alphabetWithSplSym", function(value, element) {
    return this.optional(element) || /^(?=.*[A-Za-z\d]{2,})[A-Za-z\d!@#$%^&*()_+~=`~-]{2,}/.test(value);
  });

  $.validator.addMethod("accountcardregex", function(value, element) {
    return this.optional(element) || /^[0-9]*$/.test(value);
  });

  $.validator.addMethod("dateregex", function(value, element) {
    return this.optional(element) || /^(0[1-9]|1[0-2])\/\d{4}$/.test(value);
  });

  $.validator.addMethod("blankSpace", function(value, element) {
    if(value.replaceAll(' ', '').length == 0){
      return false
    }else{
      return this.optional(element) || true
    }
  });
}
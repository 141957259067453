import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--linked-transactions"
export default class extends Controller {
  connect() {
    $('.linked_transaction_dropdowns #member_ids, #lender_ids').select2({
      placeholder: "All",
      allowClear: true
    });
    
    $('#loading_data').hide()
  }

  submitForm(){
    if($('#linked_report_form').valid())
      $('#loading_data').show()
  }

}

window.setParentApplicantId = function() {
  var parentApplicantId = []
  $('.parent_applicant_id').each(function(index, item) {
    parentApplicantId.push(item.value)
  })
  $('#applicant_ids').val(parentApplicantId.join())
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account-owner"
export default class extends Controller {
  connect() {
    accountOwnerValidator()
    $("#account_owner").validate({
      rules: {
        "account_type[name]": {
          required: true,
          alphabetsnspace: true
        }
      },
      messages: {
        "account_type[name]": {
          required: "Please enter Account Owner name.",
          alphabetsnspace: "The name can only consist of alphabets."
        }
      },
    });
  }
}

window.accountOwnerValidator = function(){
  $.validator.addMethod("alphabetsnspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
  });
}
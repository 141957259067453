import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--igw-description"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    $('#igw_product_description_v3').validate({
      rules: {
        'applicant[product_discription]': {
          required: true,
          minlength: 2,
          maxlength: 100
        }
      },
      messages: {
        'applicant[product_discription]': {
          required: 'Please enter product description.',
          minlength: 'The product description must be between 2 and 100 characters long.',
          maxlength: 'The product description must be between 2 and 100 characters long.'
        }
      }
    });

    submitBtnEnableDisable();

    $('#igw_product_description_v3').keyup(function(){
      submitBtnEnableDisable();
    });
  }
}

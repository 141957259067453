// import { Controller } from "@hotwired/stimulus"

// // Connects to data-controller="modal"
// export default class extends Controller {
//   connect() {
//   }
// }

import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap


// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false
    })
    this.modal.show()
    $('#store-modal-dialog').css("max-width","1000px")
  }

  disconnect() {
    this.modal.hide()
  }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicant"
export default class extends Controller {
  connect() {
    $('.document_forms, .new_document').validate()
    $('.new_document').each(function(item, element){
      validationSimpleForm($(element))
    })
    $('.document_forms').each(function(item, element){
      validationSimpleForm($(element))
    })
    $('.new_document select, .new_document input, .document_forms select, .document_forms input').change( function(){
      var form = $(this).closest('form')
      validationSimpleForm(form)
    })

    var selectedCheckboxes = $('.offer-checkbox:checked').length;

    console.log(selectedCheckboxes)
    if (selectedCheckboxes === 2 || selectedCheckboxes === 1) {
      $('.offer-checkbox').prop('disabled', true);
    }

    $('.offer-checkbox').change(function() {
      var seleted_offer_ids = []
      var selectedCheckboxes = $('.offer-checkbox:checked').length;
      console.log(selectedCheckboxes)
      if (selectedCheckboxes === 2 || selectedCheckboxes === 1) {
        $('#submit_button').removeClass('disabled');
        $('#cancle_button').removeClass('disabled');

        if(selectedCheckboxes === 2){
          $('.offer-checkbox:not(:checked)').prop('disabled', true);
        }else if(selectedCheckboxes === 1){
          $('.offer-checkbox:not(:checked)').prop('disabled', false);
        }

        $('.offer-checkbox:checked').each(function(item, element){
          seleted_offer_ids.push(element.value)
        })
        $('#selectedOffersField').val(seleted_offer_ids.join(','))
      } else {
        $('#submit_button').addClass('disabled');
        $('#cancle_button').addClass('disabled');
        $('.offer-checkbox').prop('disabled', false);
      }
    });

  }

  delete(event){
    let confirmed = confirm('Are you sure?')
    if(!confirmed){
      event.preventDefault()
    }
  }


  stipButtonDisable(event){
    if($('#document_identity_file').val() != ''){
      $('.btn-stip').removeAttr("disabled");
    }else{
      $('.btn-stip').attr("disabled", true);
    }
  }
}

window.validationSimpleForm = function(form){
  var inputsWithValues = 0, totalInputs = 0;
  var myInputs;
  customDocumentUploadValidation();
  myInputs = form.find('input:required, select:required').filter( ":visible" );

  myInputs.each(function(e) {
    if ($(this).val()) {
      if($(this).valid()){
        inputsWithValues += 1;
        $(this).prev().prev().css('color', '#3c7643')
      }
      else{
        $(this).prev().prev().css('color', '#a94442') 
      }
    }
  });

  totalInputs = myInputs.length

  if (inputsWithValues == totalInputs) {
    form.find(".sp_submission").prop("disabled", false);
  } else {
    form.find(".sp_submission").prop("disabled", true);
  }
}

window.customDocumentUploadValidation = function(){
  $('.identity_type_input').each(function(index, element){
   var identityType = $(element)
    identityFile = $(element).parent().parent().next().next().find('input')
    if(identityType.val() == "" && identityFile.val() == ""){
      identityFile.attr('required', false)
      identityType.attr('required', false)
    }else{
      identityFile.attr('required', true)
      identityType.attr('required', true)
    }
  })
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--vive-addtional-transactions"
export default class extends Controller {
  connect() {
    $('.vive_additional_txn_module_dropdowns #member_id').select2({
      minimumInputLength: 3,
      placeholder: "All",
      allowClear: true
    });

    $('.vive_additional_txn_module_dropdowns #member_id').change(function(){
      $('.vive_addtional_txn_btn').click()
    })

    $('.add_new_vive_tx').click(function() {
      $('#viveModel .loading').show()
      $('#viveModel #vive-credit-detail').html('')
    })
      
    console.log('#viveModel .loading')

    $('#viveModel .loading').hide()
  }

  txnUpdate(e){
    console.log('asdasdasd')
    $('.modal-backdrop').remove()
    $('.modal').remove()
  }

}

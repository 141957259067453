import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--vive-credit"
export default class extends Controller {
  connect() {
    $('#viveModel .loading').hide()
    $('#loading_data').hide()
    $('.add_new_vive_tx').click(function() {
      $('#viveModel .loading').show()
      $('#viveModel #vive-credit-detail').html('')
    })
  }
  // txnUpdate(e){
  //   console.log('asdasd')
  //   $('.modal-backdrop').remove()
  //   $('.modal').remove()
  // }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--basic-details"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });

    $('#loan_amount_formatter').keyup(function(){
      numberFormatterWithDecimal($(this))
      $('#applicant_loan_amount').val(this.value.replace(/,/g , ''))
    });

    $('#loan_amount_formatter').blur(function(){
      numberFormatterWithDecimal($(this))
      $('#applicant_loan_amount').val(this.value.replace(/,/g , ''))
    });

   $('#applicant_loan_amount').focus(function(){
      $('#loan_amount_formatter').val($('#applicant_loan_amount').val())
      $('#loan_amount_formatter').focus()
    });

    $("#loan_amount_formatter").keyup(function(){
      $('#applicant_loan_amount').focus();
    })

    $('#applicant_first_name, #applicant_last_name, #applicant_co_first_name, #applicant_co_last_name, #applicant_city, #applicant_co_city, #applicant_address, #applicant_co_address, #applicant_co_apt_unit, #applicant_apt_unit').on('blur', function() {
      var inputText = $(this).val();
      // Replace multiple spaces with a single space
      var cleanedText = inputText.replace(/\s+/g, ' ');
      // Update the input field value
      $(this).val(cleanedText);
      
      var trimmedText = $(this).val().trim();
      $(this).val(trimmedText);
    });

    $('#applicant_co_apt_unit, #applicant_apt_unit').on('blur', function() {
      var inputText = $(this).val();
      // Replace multiple spaces with a single space
      var cleanedText = inputText.replace(/\s+/g, '');
      // Update the input field value
      $(this).val(cleanedText);
      var trimmedText = $(this).val().trim();
      $(this).val(trimmedText);
    });

    $('#check_previous_address').prop('checked', false);
    validatorBasic();
    $('#basic_detail_v3').validate({
      rules: {
        'applicant[first_name]': {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        'applicant[last_name]': {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        'applicant[address]': {
          required: true,
          addressregx: true,
          poboxregex: true,
          blankSpace: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[city]': {
          required: true,
          minlength: 2,
          cityregex: true,
          maxlength: 40
        },
        'applicant[apt_unit]': {
          maxlength: 20,
          aptunit: true
        },
        'applicant[state_id]': {
          required: true
        },
        'applicant[zip]': {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        },
        'applicant[email]': {
          required: true,
          emailregex: true,
          minlength: 2,
          maxlength: 100
        },
        'applicant[phone]': {
          required: true,
          minlength: 12,
          maxlength: 12,
          phoneregex: true,
        }
      },
      messages: {
        'applicant[first_name]': {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter first name.',
          minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
        },
        'applicant[last_name]': {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter last name.',
          minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
        },
        'applicant[address]': {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.',
          blankSpace: 'Blank space not allowed'
        },
        'applicant[city]': {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        'applicant[apt_unit]': {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only Alphanumeric and all special characters are allowed.'
        },
        'applicant[state_id]': {
          required: 'Please select State.'
        },
        'applicant[zip]': {
          required: 'Please enter zip code.',
          zipregex: 'Please enter valid zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.'
        },
        'applicant[email]': {
          required: 'Please enter email address.',
          emailregex: 'Please enter valid email address.',
          maxlength: 'Please enter valid email address.',
          minlength: 'Please enter valid email address.'
        },
        'applicant[phone]': {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        }
      }
    });

    $("#co_basic_detail_v3").validate({
      rules: {
       "applicant[co_first_name]": {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        "applicant[co_last_name]": {
          required: true,
          lastNameValidation: true,
          minlength: 2,
          maxlength: 40
        },
        "applicant[co_address]": {
          required: true,
          addressregx: true,
          poboxregex: true,
          minlength: 2,
          maxlength: 100
        },
        "applicant[co_city]": {
          required: true,
          minlength: 2,
          maxlength: 40,
          cityregex: true
        },
        "applicant[co_state_id]":{
          required: true
        },
        "applicant[co_apt_unit]": {
          maxlength: 20,
          aptunit: true
        },
        "applicant[co_zip]": {
          required: true,
          minlength: 5,
          maxlength: 5,
          zipregex: true
        },
        "applicant[co_phone]": {
          required: true,
          maxlength: 12,
          minlength:12,
          phoneregex: true,
        },
        "applicant[co_email]": {
          required: true,
          emailregex: true,
          primarynotallow: true,
          minlength: 2,
          maxlength: 100
        }
      },
      messages: {
        "applicant[co_first_name]": {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter first name.',
          minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
        },
        "applicant[co_last_name]": {
          lastNameValidation: 'Please use alphabets only.',
          required: 'Please enter last name.',
          minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
        },
        "applicant[co_address]": {
          required: 'Please enter your street address.',
          poboxregex: 'Address must be a physical address.',
          minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
          addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
        },
        "applicant[co_city]": {
          required: 'Please enter city name.',
          minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
          cityregex: 'Please enter a valid city name.'
        },
        "applicant[co_state_id]": {
          required: 'Please select State.'
        },
        "applicant[co_apt_unit]": {
          maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
          aptunit: 'Sorry, only Alphanumeric and all special characters are allowed.'
        },
        "applicant[co_zip]": {
          required: 'Please enter zip code.',
          minlength: 'Zip code must be 5 numeric digits only.',
          maxlength: 'Zip code must be 5 numeric digits only.',
          zipregex: 'Please enter valid zip code.',
        },
        "applicant[co_email]": {
          required: 'Please enter email address.',
          emailregex: 'Please enter valid email address.',
          primarynotallow: "Email needs to be different from primary applicant.",
          maxlength: 'Please enter valid email address.',
          minlength: 'Please enter valid email address.',
        },
        "applicant[co_phone]": {
          required: 'Please enter mobile phone.',
          minlength: 'Your phone number must be 10 numeric digits only.',
          maxlength: 'Your phone number must be 10 numeric digits only.',
          phoneregex: 'Please enter valid mobile phone.',
        }
      }
    });
    
    if($('#prequel_detail_v3').length == 1){
      var min_loan_amount = parseInt($('#min_loan_amount').val())
      var max_loan_amount = parseInt($('#max_loan_amount').val())
      $('#prequel_detail_v3').validate({
        rules: {
          'applicant[first_name]': {
            required: true,
            lastNameValidation: true,
            minlength: 2,
            maxlength: 40
          },
          'applicant[last_name]': {
            required: true,
            lastNameValidation: true,
            minlength: 2,
            maxlength: 40
          },
          'applicant[loan_amount]': {
            required: true,
            number: true,
            min: min_loan_amount,
            max: max_loan_amount
          },
          'applicant[address]': {
            required: true,
            addressregx: true,
            poboxregex: true,
            minlength: 2,
            maxlength: 100
          },
          'applicant[city]': {
            required: true,
            minlength: 2,
            cityregex: true,
            maxlength: 40
          },
          'applicant[apt_unit]': {
            maxlength: 20,
            aptunit: true
          },
          'applicant[state_id]': {
            required: true
          },
          'applicant[zip]': {
            required: true,
            minlength: 5,
            maxlength: 5,
            zipregex: true
          },
          'applicant[email]': {
            required: true,
            emailregex: true,
            minlength: 2,
            maxlength: 100
          },
          'applicant[phone]': {
            required: true,
            minlength: 12,
            maxlength: 12,
            phoneregex: true,
          }
        },
        messages: {
            'applicant[first_name]': {
              lastNameValidation: 'Please use alphabets only.',
              required: 'Please enter first name.',
              minlength: 'Sorry, your first name must be between 2 and 40 characters long.',
              maxlength: 'Sorry, your first name must be between 2 and 40 characters long.'
            },
            'applicant[last_name]': {
              lastNameValidation: 'Please use alphabets only.',
              required: 'Please enter last name.',
              minlength: 'Sorry, your last name must be between 2 and 40 characters long.',
              maxlength: 'Sorry, your last name must be between 2 and 40 characters long.'
            },
            'applicant[loan_amount]': {
              required: 'Please enter requested amount.',
              number: 'Please enter requested amount.',
              min: 'Sorry, requested amount must be greater than ' + min_loan_amount + '.',
              max: 'Sorry, requested amount must be between ' + min_loan_amount + ' and ' + max_loan_amount + '.'
            },
            'applicant[address]': {
              required: 'Please enter your street address.',
              poboxregex: 'Address must be a physical address',
              minlength: 'Sorry, your street address must be between 2 and 100 characters long.',
              maxlength: 'Sorry, your street address must be between 2 and 100 characters long.',
              addressregx: 'Sorry, only letters(a-z), numbers & period(.) are allowed.'
            },
            'applicant[city]': {
              required: 'Please enter city name.',
              minlength: 'Sorry, your city name must be between 2 and 40 characters long.',
              maxlength: 'Sorry, your city name must be between 2 and 40 characters long.',
              cityregex: 'Please enter a valid city name.'
            },
            'applicant[apt_unit]': {
              maxlength: 'Sorry, your apt/unit must be between 2 and 20 characters long.',
              aptunit: 'Sorry, only Alphanumeric and all special characters are allowed.'
            },
            'applicant[state_id]': {
              required: 'Please select State.'
            },
            'applicant[zip]': {
              required: 'Please enter zip code.',
              zipregex: 'Please enter valid zip code.',
              minlength: 'Zip code must be 5 numeric digits only.',
              maxlength: 'Zip code must be 5 numeric digits only.'
            },
            'applicant[email]': {
              required: 'Please enter email address.',
              emailregex: 'Please enter valid email address.',
              maxlength: 'Please enter valid email address.',
              minlength: 'Please enter valid email address.'
            },
            'applicant[phone]': {
              required: 'Please enter mobile phone.',
              minlength: 'Your phone number must be 10 numeric digits only.',
              maxlength: 'Your phone number must be 10 numeric digits only.',
              phoneregex: 'Please enter valid mobile phone.',
            }
          }
      });  
    }
    
    submitBtnEnableDisable();

    $('input').focusout(function(){
      submitBtnEnableDisable();
    });

    $('select').change(function(){
      submitBtnEnableDisable();
    });

    $('#basic_detail_v3, #co_basic_detail_v3, #prequel_detail_v3').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });

    $('input[name="applicant[phone]"]').keyup(function(){
      addDashes($(this))
    })

    $('input[name="applicant[co_phone]"]').keyup(function(){
      addDashes($(this))
    })
  }
  emailtrimInput = function(event) {
    var element = event.currentTarget
    let ipv = element.value
    let trimIpv = ipv.trim()
    if(trimIpv != ipv){
      $(element).val(trimIpv)
    }
  }

  setPreviousAddress =function(event) {
    var element = event.currentTarget
    if(element.checked){
      $('#applicant_co_address').val($('#primaryApplicant').data('street-address'));
      $('#applicant_co_city').val($('#primaryApplicant').data('city'));
      $('#applicant_co_state_id').val($('#primaryApplicant').data('state'));
      $('#applicant_co_zip').val($('#primaryApplicant').data('zip'));
      $('#applicant_co_apt_unit').val($('#primaryApplicant').data('apt-unit'));
     }
     else{
      $('#applicant_co_address').val('');
      $('#applicant_co_city').val('');
      $('#applicant_co_state_id').val('');
      $('#applicant_co_zip').val();
      $('#applicant_co_apt_unit').val('');
     }
  }
}

window.validatorBasic = function(){
  $.validator.addMethod("phoneregex", function(value, element) {
    return this.optional(element) || /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
  });

 $.validator.addMethod("blankSpace", function(value, element) {
   if(value.replaceAll(' ', '').length == 0){
      return false
    }else{
      return this.optional(element) || true
    }
  });

  $.validator.addMethod("alphabetsnspace", function(value, element) {
    return this.optional(element) || /^([a-zA-Z.,'-]+\s)*[a-zA-Z.,'-]+$/.test(value);
  });

  $.validator.addMethod("lastNameValidation", function(value, element) {
    return this.optional(element) || /^([a-zA-Z., '-])*[a-zA-Z., '-]+$/.test(value);
  });

  $.validator.addMethod("alphabetswithspace", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
  });

  $.validator.addMethod("alphanumeric", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 ]+$/.test(value);
  });

  $.validator.addMethod("addressregx", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 .,-]+$/.test(value);
  });

  $.validator.addMethod("emailregex", function(value, element) {
    return this.optional(element) || /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(value);
  });

  $.validator.addMethod("cityregex", function(value, element) {
    return this.optional(element) || /^[a-zA-Z ]+(?:[- ][a-zA-Z ]+)*$/.test(value);
  });

  $.validator.addMethod("zipregex", function(value, element) {
    return this.optional(element) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
  });

  $.validator.addMethod("aptunit", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 ,!@#\$%\^\&*\)\(+=._'-]+$/.test(value);
  });
  $.validator.addMethod("poboxregex", function(value, element) {
    return this.optional(element) || !/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box[0-9]{0,10})\b/i.test(value);
  });

  $.validator.addMethod("primarynotallow", function(value, element) {
   var primary_email = $('#primary_email').val();
    return this.optional(element) || (primary_email.toLowerCase() != value.toLowerCase());
  });
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="member-lender-report"
export default class extends Controller {
  connect() {
    $('#tu_mode, #member_ids, #lender_ids').select2({
      placeholder: "All"
    });
    $('#loading_data').hide()
  }
  showSpinner() {
    $('#loading_data').show()
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  connect() {
    if (Notification.permission === 'granted' && $('.push_notification_sweetpay').length > 0) {
      var body = this.context.scope.element.dataset.message
      var count = this.context.scope.element.dataset.count
      var title = 'SweetPay Notification'
      var options = { body: body }
      console.log(options)
      $('#notification-items').html('<span class="count"></span>')
      // $('.count').text(count)
      // $('.count').attr('id',"count-"+count)
      new Notification(title, options)
    }

    if($('.push_notification_sweetpay').length > 0){
      $.ajax({
        type: 'GET',
        url: '/admin/notifications/notify_update',
        success: function(data) {
          // console.log('--->')
        }
      });
    }
  }
}

